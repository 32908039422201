import React from "react";
import { useNavigate } from "react-router-dom";

const HomeIcon = ({ link, title, imageLink }) => {
    const nav = useNavigate()
  return (
      <div className="card" onClick={()=>{nav(link)}}>
        <img src={imageLink} />
        <strong>{title}</strong>
      </div>
  );
};

export default HomeIcon;
