import React from "react";
import Meal from "./Meal";

const MealList = (props) => {
  // props.data = array for how many workouts

  const solve = () => {
    const length = props.count;
    const data = props.mealData
    console.log(data)

    let collection = [];
    for (let i = 0; i < length; i++) {
      collection[i] = (
        <Meal
          pid={"pid"}
          pd={data ? data.protein[i] : null}
          cid={"cid"}
          cd={data ? data.carbs[i] : null}
          fid={"fid"}
          fd={data ? data.fats[i] : null}
          mealNumber={i + 1}
          clientContext={props.clientContext}
        />
      );
    }
    return collection;
  };

  return <>{solve()}</>;
};

export default MealList;
