import React, { useEffect, useState } from "react";
import WorkoutList from "./WorkoutList";

const WorkoutDay = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [adminIsOpen, setAdminIsOpen] = useState(true);
  const [workoutListQuantity, setWorkoutListQuantity] = useState(
    JSON.parse(props.data).workouts.length
  );
  const [data, setData] = useState(JSON.parse(props.data));
  const client = props.client;
  const workoutList = props.workoutList;

  const handleAdd = () => {
    setWorkoutListQuantity(workoutListQuantity + 1);
  };

  const handleClear = () => {
    setWorkoutListQuantity(0);
  };

  const handleRemove = () => {
    setWorkoutListQuantity(workoutListQuantity - 1);
  };

  const toggleIsOpen = () => {
    let state = isOpen;
    setIsOpen(!state);
    const el = document.querySelector(`#content${props.day}`);
    el.classList.toggle(`visible`);
  };

  const toggleIsOpenAdmin = () => {
    let state = isOpen;
    setIsOpen(!state);
    const el = document.querySelector(`#content${props.day}`);
    el.classList.toggle(`visible`);
  };

  if (!client) {
    return (
      <div className="workoutDay">
        <div className="header" onClick={toggleIsOpenAdmin}>
          <h3>{props.day}</h3>
          <img
            src={isOpen ? `/assets/photos/28.png` : `/assets/photos/27.png`}
            alt=""
          />
        </div>
        <div id={`content${props.day}`} className="content">
          <div className="label">
            <h4>Muscle Group: </h4>
            <input
              id={"mg" + props.day}
              type="text"
              placeholder="muscle group"
              defaultValue={data.musclegroup}
            />
          </div>
          <WorkoutList
            workoutList={workoutList}
            client={client}
            count={workoutListQuantity}
            day={props.day}
            data={data}
          />
          <div className="dayControls">
            <button onClick={handleAdd}>Add Workout</button>
            <button onClick={handleRemove}>Remove Workout</button>
            <button onClick={handleClear}>Clear Day</button>
          </div>
          <img src="/assets/photos/28.png" alt="" onClick={toggleIsOpenAdmin} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="workoutDay">
        <div className="header" onClick={toggleIsOpen}>
          <h3>{props.day}</h3>
          <img
            src={isOpen ? `/assets/photos/28.png` : `/assets/photos/27.png`}
            alt=""
          />
        </div>
        <div id={`content${props.day}`} className="content">
          <div className="label">
            <h4>Muscle Group: {data.musclegroup}</h4>
          </div>
          <WorkoutList
            client={client}
            workoutList={workoutList}
            count={workoutListQuantity}
            day={props.day}
            data={data}
          />
          <img src="/assets/photos/28.png" alt="" onClick={toggleIsOpen} />
        </div>
      </div>
    );
  }
};

export default WorkoutDay;
