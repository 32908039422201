import React, { useState } from "react";
import loginHelper from "../../helpers/loginHelper";

const ClientLogin = () => {
  const [buttonText, setButtontext] = useState("Login");

  const handleLogin = async () => {
    setButtontext("Working...");
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const logger = new loginHelper();
    const response = await logger.tryLogin(email, password);
    const result = await response.json();
    if (result.status != `ok`) {
      alert(result.msg);
    }
    setButtontext("Login");
    if (result.status == `ok`) {
      window.location.replace("/client");
    }
  };

  const forgotPassword = async () => {
    const email = prompt("Please enter your email to reset your password: ");
    if (email) {
      const l = new loginHelper();
      const response = await l.triggerPasswordReset(email);
      const body = await response.json();
      const { msg } = body;
      alert(msg);
    } else {
      alert("email cannot be blank!");
    }
  };

  return (
    <div className="clientLogin">
      <div className="window">
        <img src="/assets/photos/logo-small.png" />
        <input
          type="email"
          id="email"
          placeholder="Enter your email"
          required
        />
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          required
        />
        <button onClick={handleLogin}>{buttonText}</button>
        <a rel="noreferrer noopener" href="https://trainedbyturp.com">
          Main Site
        </a>
        <p onClick={forgotPassword}>Forgot Password</p>
      </div>
    </div>
  );
};

export default ClientLogin;
