import React, { useEffect } from "react";

const DayNotes = ({ selectedDay, exerciseNotes, workoutList }) => {
  useEffect(() => {
    console.log(selectedDay.workouts);
  }, []);

  const solveWorkoutText = (wd) => {
    //  get workout associated with id
    const target = wd;
    let match;
    workoutList.forEach((workout) => {
      const { _id } = workout;
      if (target == _id) {
        match = workout;
      }
    });
    if (match) {
      return match.name;
    } else {
      return wd;
    }
  };

  const populateNotes = () => {
    const length = selectedDay.workouts.length;
    const payload = [];
    for (let i = 0; i < length; i++) {
      payload.push(
        <div>
          <div className="rowWrapper">
            <strong>Workout: </strong>
            <p>{solveWorkoutText(selectedDay.workouts[i])}</p>
          </div>
          <div className="rowWrapper">
            <strong>Sets: </strong>
            <p>{selectedDay.sets[i]} </p>
          </div>
          <div className="rowWrapper">
            <strong>Reps: </strong>
            <p>{selectedDay.reps[i]} </p>
          </div>
          <textarea
            name="notes"
            id="notes"
            placeholder="Record your notes here!"
            disabled
          >
            {exerciseNotes[i]}
          </textarea>
        </div>
      );
    }
    return payload;
  };

  return <>{populateNotes()}</>;
};

export default DayNotes;
