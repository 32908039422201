export default class dataHelper {
  async createWorkout(name, notes, videolink) {
    try {
      let response = await fetch("/api/workout/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          name: name,
          notes: notes,
          videolink: videolink,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateWorkout(target, name, notes, videolink, musclegroup) {
    try {
      let response = await fetch("/api/workout/update", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          name: name,
          notes: notes,
          videolink: videolink,
          target: target,
          musclegroup: musclegroup,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getWorkoutList() {
    try {
      let response = await fetch("/api/workout/getlist", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getWorkout(target) {
    try {
      let response = await fetch("/api/workout/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          target: target,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getNutritionPlan(target) {
    try {
      let response = await fetch("/api/nutritionplan/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          target: target,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async copyNutritionPlan(from, to) {
    try {
      let response = await fetch("/api/nutritionplan/copy", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          from: from,
          to: to,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getCurrentNutritionPlan() {
    try {
      let response = await fetch("/api/nutritionplan/getcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateNutritionPlan(target, notes, macros, meals, fats, plan) {
    try {
      let response = await fetch("/api/nutritionplan/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Delete nutrition plan",
          target: target,
        }),
      });
      let response2 = await fetch("/api/nutritionplan/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Add nutrition plan",
          target: target,
          notes: notes,
          macros: macros,
          meals: meals,
          fats: fats,
          plan: plan,
        }),
      });
      return response2;
    } catch (error) {
      return error;
    }
  }
  async getWorkoutPlan(target) {
    try {
      let response = await fetch("/api/workoutplan/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          target: target,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async copyWorkoutPlan(from, to) {
    try {
      let response = await fetch("/api/workoutplan/copy", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          from: from,
          to: to,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async getCurrentNotes(target) {
    try {
      let response = await fetch("/api/notes/getcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ target: target }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async getTargetNotes(target, exercise) {
    try {
      let response = await fetch("/api/notes/gettarget", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ target: target, exercise: exercise }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async saveWorkoutNotes(target, note) {
    try {
      let response = await fetch("/api/notes/save", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ target: target, note: note }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async getPhotoLinks(belongsToID) {
    try {
      let response = await fetch(`/api/checkins/getphotolinks`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: belongsToID,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async uploadCheckinPhotos(formData) {
    try {
      let response = await fetch(`/api/checkins/uploadphotos`, {
        method: "POST",
        credentials: "include",
        body: formData,
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async getCurrentWorkoutPlan() {
    try {
      let response = await fetch("/api/workoutplan/getcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateWorkoutPlan(
    target,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  ) {
    try {
      let response = await fetch("/api/workoutplan/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          target: target,
        }),
      });
      let response2 = await fetch("/api/workoutplan/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          target: target,
        }),
      });
      return response2;
    } catch (error) {
      return error;
    }
  }
  async getNewCheckins() {
    try {
      let response = await fetch("/api/checkins/getnew", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getOldCheckins(target) {
    try {
      let response = await fetch("/api/checkins/getold", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async uploadCheckin(
    belongsToID,
    email,
    fname,
    lname,
    weight,
    bodyfat,
    adherence,
    struggles,
    digestion,
    hunger,
    sleep,
    stressors,
    feelings
  ) {
    try {
      let response = await fetch("/api/checkins/upload", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          belongsToID: belongsToID,
          email: email,
          fname: fname,
          lname: lname,
          weight: weight,
          bodyfat: bodyfat,
          adherence: adherence,
          struggles: struggles,
          digestion: digestion,
          hunger: hunger,
          sleep: sleep,
          stressors: stressors,
          feelings: feelings,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async processCheckin(target, dueIn) {
    try {
      let response = await fetch("/api/checkins/process", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          target: target,
          dueIn: dueIn,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async sendCheckin(target) {
    try {
      let response = await fetch("/api/checkins/send", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          target: target,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
