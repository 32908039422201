import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import userHelper from "../helpers/userHelper";
import { useNavigate } from "react-router";

const PortalSchedulingManagerModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(``);
  const [employeeData, setEmployeeData] = useState(null);

  const nav = useNavigate()

  useEffect(() => {
    (async () => {
      await refreshEmployeeList();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectedEmployee`).value = selectedEmployee;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const refreshEmployeeList = async () => {
    const helper = new userHelper();
    const response = await helper.getElevatedList();
    const result = await response.json();
    const { data } = result;
    setEmployeeList(data);
  };

  const fetchEmployee = async () => {
    const employee = document.querySelector(`#selectedEmployee`).value;
    setLoaded(false);
    setSelectedEmployee(employee);
    const uman = new userHelper();
    const response = await uman.getUserData(employee);
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setEmployeeData(data);
    setLoaded(true);
  };

  const populateSelectBox = () => {
    let payload = [];
    employeeList.forEach((employee) => {
      payload.push(genTag(employee.fname, employee.lname, employee._id));
    });
    return payload;
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const updateCalendarLink = async () => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.updateCalendarLink(
      selectedEmployee,
      document.querySelector(`#newlink`).value
    );
    const result = await response.json();
    alert(result.msg);
    await refreshEmployeeList();
    const response1 = await uman.getUserData(selectedEmployee);
    const result1 = await response1.json();
    const { data } = result1;
    setEmployeeData(data);
    setLoaded(true);
  };

  const updateCalendarLinkOnlineOnly = async () => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.updateCalendarLinkOnlineOnly(
      selectedEmployee,
      document.querySelector(`#newlink2`).value
    );
    const result = await response.json();
    alert(result.msg);
    await refreshEmployeeList();
    const response1 = await uman.getUserData(selectedEmployee);
    const result1 = await response1.json();
    const { data } = result1;
    setEmployeeData(data);
    setLoaded(true);
  };

  const goToCalendar = () => {
    const { calendarLink } = employeeData;
    if(calendarLink != null){
      window.open(calendarLink, '_blank').focus();
    } else {
      alert(`Link appears invalid! Try again.`)
    }
  };
  const goToCalendarOnline = () => {
    const { calendarLinkOnlineOnly } = employeeData;
    if(calendarLinkOnlineOnly != null){
      window.open(calendarLinkOnlineOnly, '_blank').focus();
    } else {
      alert(`Link appears invalid! Try again.`)
    }
  };

  if (!loaded) {
    return <Loading />;
  } else if (loaded) {
    return (
      <div className="portalSchedulingManagerModule">
        <h2>Scheduling Manager</h2>
        <p>
          Set scheduling information for yourself and your employees here. Every
          trainer must have a google calendar linked to their account in order
          for clients to be able to book through their google link.
        </p>
        <h3>Select Employee</h3>
        <select id="selectedEmployee" onChange={fetchEmployee}>
          <option value="">SELECT AN EMPLOYEE TO EDIT</option>
          {populateSelectBox()}
        </select>
        {selectedEmployee ? (
          <>
            <h3>In Person Scheduling Link</h3>
            <input
              id="newlink"
              type="string"
              placeholder="enter new in person calendar link"
              defaultValue={employeeData.calendarLink}
            />
            <button onClick={updateCalendarLink}>Update Calendar Link</button>
            <button onClick={goToCalendar}>See Schedule</button>
            <h3>Online Scheduling Link</h3>
            <input
              id="newlink2"
              type="string"
              placeholder="enter new online calendar link"
              defaultValue={employeeData.calendarLinkOnlineOnly}
            />
            <button onClick={updateCalendarLinkOnlineOnly}>Update Calendar Link</button>
            <button onClick={goToCalendarOnline}>See Schedule</button>
          </>
        ) : null}
      </div>
    );
  }
};

export default PortalSchedulingManagerModule;
