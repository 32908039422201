import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import userHelper from "../helpers/userHelper";
import { useNavigate } from "react-router";

const PortalEmployeeDatabase = () => {
  const [loaded, setLoaded] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(``);
  const [employeeData, setEmployeeData] = useState(null);

  const nav = useNavigate()

  useEffect(() => {
    (async () => {
      await refreshEmployeeList();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectedEmployee`).value = selectedEmployee;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const refreshEmployeeList = async () => {
    const helper = new userHelper();
    const response = await helper.getEmployeeList();
    const result = await response.json();
    const { data } = result;
    setEmployeeList(data);
  };

  const createEmployee = async () => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.createEmployee(
      document.querySelector(`#fname`).value,
      document.querySelector(`#lname`).value,
      document.querySelector(`#email`).value,
      document.querySelector(`#phone`).value,
      document.querySelector(`#address`).value
    );
    const result = await response.json();
    alert(result.msg);
    await refreshEmployeeList();
    setLoaded(true);
  };

  const fetchEmployee = async () => {
    const employee = document.querySelector(`#selectedEmployee`).value;
    setLoaded(false);
    setSelectedEmployee(employee);
    const uman = new userHelper();
    const response = await uman.getUserData(employee);
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setEmployeeData(data);
    setLoaded(true);
  };

  const updateemployee = async () => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.updateUser(
      selectedEmployee,
      document.querySelector(`#newfname`).value,
      document.querySelector(`#newlname`).value,
      document.querySelector(`#newemail`).value,
      document.querySelector(`#newphone`).value,
      document.querySelector(`#newaddress`).value
    );
    const result = await response.json();
    alert(result.msg);
    await refreshEmployeeList();
    const response1 = await uman.getUserData(selectedEmployee);
    const result1 = await response1.json();
    const { data } = result1;
    setEmployeeData(data);
    setLoaded(true);
  };

  const removeemployee = async () => {
    const input = confirm(
      `Are you sure you want to delete this employee? This action cannot be undone and will unassign all clients currently assigned to that employee.`
    );
    if (input === true) {
      setLoaded(false);
      const uman = new userHelper();
      const response = await uman.purgeUser(selectedEmployee);
      const result = await response.json();
      await refreshEmployeeList();
      const response1 = await uman.getUserData(selectedEmployee);
      const result1 = await response1.json();
      const { data } = result1;
      setEmployeeData(data);
      alert(result.msg);
      setSelectedEmployee("")
      setLoaded(true);
    } else {
      return;
    }
  };

  const populateSelectBox = () => {
    let payload = [];
    employeeList.forEach((employee) => {
      payload.push(genTag(employee.fname, employee.lname, employee._id));
    });
    return payload;
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  if (loaded) {
    return (
      <div className="portalEmployeeDatabase">
        <h2>Employee Database</h2>
        <h3>Create Employee</h3>
        <input
          id="fname"
          type="fname"
          placeholder="enter employee first name"
        />
        <input id="lname" type="lname" placeholder="enter employee last name" />
        <input id="email" type="email" placeholder="enter employee email" />
        <input id="phone" type="phone" placeholder="enter employee phone" />
        <input
          id="address"
          type="address"
          placeholder="enter employee address"
        />
        <button onClick={createEmployee}>Create Employee</button>
        <h3>Edit Employee</h3>
        <select id="selectedEmployee" onChange={fetchEmployee}>
          <option value="">SELECT AN EMPLOYEE TO EDIT</option>
          {populateSelectBox()}
        </select>
        {selectedEmployee ? (
          <>
            <input
              id="newfname"
              type="fname"
              placeholder="enter employee first name"
              defaultValue={employeeData.fname}
            />
            <input
              id="newlname"
              type="lname"
              placeholder="enter employee last name"
              defaultValue={employeeData.lname}
            />
            <input
              id="newemail"
              type="email"
              placeholder="enter employee email"
              defaultValue={employeeData.email}
            />
            <input
              id="newphone"
              type="phone"
              placeholder="enter employee phone"
              defaultValue={employeeData.phone}
            />
            <input
              id="newaddress"
              type="address"
              placeholder="enter employee address"
              defaultValue={employeeData.address}
            />
            <button onClick={updateemployee}>Update Employee</button>
            <button onClick={removeemployee}>Remove Employee</button>
          </>
        ) : null}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalEmployeeDatabase;
