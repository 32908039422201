import React, { useEffect, useState } from "react";
import dataHelper from "../../helpers/OLDdataHelper";
import { useNavigate, useParams } from "react-router";
import userHelper from "../../helpers/userHelper";

const PasswordReset = () => {
  const [buttonText, setButtonText] = useState("RESET PASSWORD");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log(params);
  }, []);

  const resetPassword = async () => {
    const { resetID } = params;
    setButtonText("Working...");
    const p1 = document.getElementById("newpass1").value;
    const p2 = document.getElementById("newpass2").value;
    if (p1 == p2 && p2.length > 1) {
      const u = new userHelper();
      const response = await u.resetPasswordClient(resetID, p1);
      const body = await response.json();
      if (body.status == "ok") {
        alert(body.msg);
        navigate("/login");
      } else {
        console.log(body.err);
        alert("There was a server error. Please try again");
        setButtonText("RESET PASSWORD");
      }
    } else {
      alert("Passwords must not be blank, and they must match");
      setButtonText("RESET PASSWORD");
    }
  };

  return (
    <div className="clientLogin">
      <div className="window">
        <img src="/assets/photos/logo-small.png" />
        <h1>Password Reset</h1>
        <label>New Password:</label>
        <input id="newpass1" type="password" placeholder="**********" />
        <label>Confirm New Password:</label>
        <input id="newpass2" type="password" placeholder="**********" />
        <button onClick={resetPassword}>{buttonText}</button>
        <p id="return">Return to main site</p>
      </div>
    </div>
  );
};

export default PasswordReset;
