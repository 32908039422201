import React, { useEffect, useState } from "react";
import dataHelper from "../../helpers/dataHelper";
import Loading from "../Loading";
import DayNotes from "./DayNotes";

const CheckinForms = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [showPhoto, setShowPhoto] = useState(null);
  const [photoLinks, setPhotoLinks] = useState([]);
  const [formattedDate, setFormattedDate] = useState(null);
  const [workoutList, setWorkoutList] = useState([]);
  const [day, setDay] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [workoutPlan, setWorkoutPlan] = useState({});
  const [exerciseNotes, setExerciseNotes] = useState([]);
  const [showDayNotes, setShowDayNotes] = useState(false);

  useEffect(() => {
    const dateObj = new Date();
    dateObj.setTime(props.date);
    setFormattedDate(dateObj.toDateString());
    getData();
  }, []);

  useEffect(() => {
    try {
      let select = document.getElementById(`selectedDay${props.belongsToID}`);
      select.value = day;
    } catch (err) {
      return;
    }
  }, [loaded]);

  useEffect(() => {
  }, [selectedDay]);

  const getData = async () => {
    const dman = new dataHelper();
    const response = await dman.getPhotoLinks(props.belongsToID);
    const result = await response.json();
    const response2 = await dman.getWorkoutPlan(props.belongsToID);
    const result2 = await response2.json();
    const response3 = await dman.getWorkoutList();
    const result3 = await response3.json();
    setWorkoutList(result3.data);
    setWorkoutPlan(result2.data);
    setPhotoLinks(result.data);
    setLoaded(true);
  };

  const processCheckin = async (target) => {
    const dueIn = document.querySelector(`#dueIn${props.belongsToID}`).value;
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.processCheckin(target, dueIn);
    const body = await response.json();
    alert(body.msg);
    await props.refreshHandler();
  };

  const populatePhotos = () => {
    const payload = [];
    photoLinks.forEach((link) => {
      payload.push(tag(link));
    });
    return payload;
  };

  const tag = (filename) => {
    return <img src={`/uploads/${filename}`} />;
  };

  const switchDay = async (day) => {
    setLoaded(false);
    setShowDayNotes(false);
    setSelectedDay(null);
    let wp;
    let notes = [];
    const dman = new dataHelper();
    const finish = async () => {
      const wo = JSON.parse(wp);
      const exerciseNotesData = [];
      for (let i = 0; i < wo.workouts.length; i++) {
        const response = await dman.getTargetNotes(
          props.belongsToID,
          wo.workouts[i]
        );
        const result = await response.json();
        exerciseNotesData.push(result.data);
      }
      setExerciseNotes(exerciseNotesData);
      setSelectedDay(wo);
      setShowDayNotes(true);
    };
    switch (day) {
      case "monday":
        wp = workoutPlan.monday;
        setDay("monday");
        finish();
        break;
      case "tuesday":
        wp = workoutPlan.tuesday;
        setDay("tuesday");
        finish();
        break;
      case "wednesday":
        wp = workoutPlan.wednesday;
        setDay("wednesday");
        finish();
        break;
      case "thursday":
        wp = workoutPlan.thursday;
        setDay("thursday");
        finish();
        break;
      case "friday":
        wp = workoutPlan.friday;
        setDay("friday");
        finish();
        break;
      case "saturday":
        wp = workoutPlan.saturday;
        setDay("saturday");
        finish();
        break;
      case "sunday":
        wp = workoutPlan.sunday;
        setDay("sunday");
        finish();
        break;
      case "":
        setShowDayNotes(false);
        break;
    }

    setLoaded(true);
  };

  if (loaded) {
    return (
      <div className="checkinForm">
        <h3>Checkin Form</h3>
        <p>
          <strong>Date:</strong> {formattedDate}
        </p>
        <p>
          <strong>First Name:</strong> {props.fname}
        </p>
        <p>
          <strong>Last Name:</strong> {props.lname}
        </p>
        <p>
          <strong>Email:</strong> {props.email}
        </p>
        <p>
          <strong>Weight:</strong> {props.weight}
        </p>
        <p>
          <strong>Bodyfat:</strong> {props.bodyfat}
        </p>
        <p>
          <strong>Adherence to the plan:</strong> {props.adherence}
        </p>
        <p>
          <strong>Struggles:</strong> {props.struggles}
        </p>
        <p>
          <strong>Digestive Health:</strong> {props.digestion}
        </p>
        <p>
          <strong>Hunger:</strong> {props.hunger}
        </p>
        <p>
          <strong>Sleep Schedule:</strong> {props.sleep}
        </p>
        <p>
          <strong>Other Stressors:</strong> {props.stressors}
        </p>
        <p>
          <strong>General Feelings:</strong> {props.feelings}
        </p>
        <p
          className="photoControl"
          onClick={() => {
            const current = showPhoto;
            setShowPhoto(!current);
          }}
        >
          {showPhoto ? "Hide Photos" : "Show Photos"}
        </p>
        {showPhoto ? populatePhotos() : null}
        <p>
          <strong>View Client Notes:</strong>
        </p>
        <select
          id={`selectedDay${props.belongsToID}`}
          onChange={(e) => {
            switchDay(e.target.value);
          }}
        >
          <option value="">SELECT A DAY</option>
          <option value="monday">Monday</option>
          <option value="tuesday">Tuesday</option>
          <option value="wednesday">Wednesday</option>
          <option value="thursday">Thursday</option>
          <option value="friday">Friday</option>
          <option value="saturday">Saturday</option>
          <option value="sunday">Sunday</option>
        </select>

        {showDayNotes ? (
          <DayNotes
            exerciseNotes={exerciseNotes}
            selectedDay={selectedDay}
            workoutList={workoutList}
          />
        ) : null}

        <div className="pad">
          <p>
            <strong>Next checkin unlocks in: </strong>
          </p>
          <select id={`dueIn${props.belongsToID}`}>
            <option value="1">1 Week</option>
            <option value="2">2 Weeks</option>
            <option value="3">3 Weeks</option>
            <option value="4">4 Weeks</option>
            <option value="5">5 Weeks</option>
            <option value="6">6 Weeks</option>
          </select>
        </div>
        <button
          onClick={() => {
            processCheckin(props._id);
          }}
        >
          PROCESS CHECKIN
        </button>
      </div>
    );
  } else return <Loading />;
};

export default CheckinForms;
