import React, { createContext, useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import WorkoutDay from "./parts/WorkoutDay";
import { useNavigate } from "react-router";

const PortalWorkoutPlanModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [workoutPlan, setWorkoutPlan] = useState(null);
  const [workoutList, setWorkoutList] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const dman = new dataHelper();
    const response = await dman.getCurrentWorkoutPlan();
    const result = await response.json();
    const response2 = await dman.getWorkoutList();
    const result2 = await response2.json();
    if (result.status != `ok` || result2.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setWorkoutPlan(data);
    setWorkoutList(result2.data);
    setLoaded(true);
  };

  if (loaded) {
    return (
      <div className="portalWorkoutPlanModule">
        <h2>Workout Plan</h2>
        <p>See your workout plan here</p>
        {workoutPlan ? (
          <div className="display">
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Monday"
              data={workoutPlan.monday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Tuesday"
              data={workoutPlan.tuesday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Wednesday"
              data={workoutPlan.wednesday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Thursday"
              data={workoutPlan.thursday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Friday"
              data={workoutPlan.friday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Saturday"
              data={workoutPlan.saturday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={true}
              day="Sunday"
              data={workoutPlan.sunday}
            />
          </div>
        ) : (
          <strong>
            Your workout plan is not ready yet. Please check back later!
          </strong>
        )}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalWorkoutPlanModule;
