import React from "react";
import { Route, Routes, Navigate, Router } from "react-router-dom";
import App from "../client/App";
import TermsOfUse from "../client/pages/legal/TermsOfUse";
import PrivacyPolicy from "../client/pages/legal/PrivacyPolicy";
import CookiePolicy from "../client/pages/legal/CookiePolicy";
import ClientLogin from "../client/pages/client/ClientLogin";
import ClientPortal from "../client/pages/client/ClientPortal";
import PasswordReset from "../client/pages/client/PasswordReset";
import RefundPolicy from "../client/pages/legal/RefundPolicy";
import PortalHomeModule from "../client/comps/PortalHomeModule";
import PortalAccountModule from "../client/comps/PortalAccountModule";
import PortalClientDatabase from "../client/comps/PortalClientDatabase";
import PortalEmployeeTasking from "../client/comps/PortalEmployeeTasking";
import PortalEmployeeDatabase from "../client/comps/PortalEmployeeDatabase";
import PortalWorkoutManager from "../client/comps/PortalWorkoutManager";
import PortalWorkoutPlanningModule from "../client/comps/PortalWorkoutPlanningModule";
import PortalNutritionPlanningModule from "../client/comps/PortalNutritionPlanningModule";
import PortalNutritionPlanModule from "../client/comps/PortalNutritionPlanModule";
import PortalNewCheckinsModule from "../client/comps/PortalNewCheckinsModule.js";
import PortalOldCheckinsModule from "../client/comps/PortalOldCheckinsModule";
import PortalSchedulingModule from "../client/comps/PortalSchedulingModule";
import PortalSchedulingManagerModule from "../client/comps/PortalSchedulingManagerModule";
import PortalCheckinsModule from "../client/comps/PortalCheckinsModule";
import PortalWorkoutPlanModule from "../client/comps/PortalWorkoutPlanModule";
import PortalWorkoutTutorialsModule from "../client/comps/PortalWorkoutTutorialsModule";
import PortalWorkoutTracker from "../client/comps/PortalWorkoutTracker.js";
import PortalClientNotes from "../client/comps/PortalClientNotes.js";
import PortalEComModule from "../client/comps/PortalEComModule.js";

export default function Layout(props) {
  return (
    <Routes>
      <Route path="/client" element={<ClientPortal />}>
        <Route index element={<PortalHomeModule />} />
        <Route path="ecom" element={<PortalEComModule />} />
        <Route path="account" element={<PortalAccountModule />} />
        <Route path="clientdatabase" element={<PortalClientDatabase />} />
        <Route path="employeetasking" element={<PortalEmployeeTasking />} />
        <Route path="employeedatabase" element={<PortalEmployeeDatabase />} />
        <Route path="workoutmanager" element={<PortalWorkoutManager />} />
        <Route path="workouttracker" element={<PortalWorkoutTracker />} />
        <Route path="clientnotes" element={<PortalClientNotes />} />
        <Route
          path="workoutplanning"
          element={<PortalWorkoutPlanningModule />}
        />
        <Route path="workoutplan" element={<PortalWorkoutPlanModule />} />
        <Route
          path="nutritionplanning"
          element={<PortalNutritionPlanningModule />}
        />
        <Route path="nutritionplan" element={<PortalNutritionPlanModule />} />
        <Route path="newcheckins" element={<PortalNewCheckinsModule />} />
        <Route path="oldcheckins" element={<PortalOldCheckinsModule />} />
        <Route path="checkinscheduling" element={<PortalSchedulingModule />} />
        <Route
          path="schedulingmanager"
          element={<PortalSchedulingManagerModule />}
        />
        <Route path="checkins" element={<PortalCheckinsModule />} />
        <Route path="tutorials" element={<PortalWorkoutTutorialsModule />} />
      </Route>
      <Route path="/login" element={<ClientLogin />} />
      <Route path="/passwordreset/:resetID" element={<PasswordReset />} />
      <Route path="/legal" element={<App />}>
        <Route index element={<TermsOfUse />} />
        <Route path="tou" element={<TermsOfUse />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="cookies" element={<CookiePolicy />} />
        <Route path="refund" element={<RefundPolicy />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
