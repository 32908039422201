import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import userHelper from "../helpers/userHelper";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";

const PortalCheckinsModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [checkinRequired, setCheckinRequired] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const uman = new userHelper();
    const response = await uman.getClientData();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    const { needsCheckin } = data;
    const today = new Date();
    const now = today.getTime();
    now > needsCheckin ? setCheckinRequired(true) : setCheckinRequired(false);
    setClientData(data);
    setLoaded(true);
  };

  const submitCheckinForm = async () => {
    const email = clientData.email;
    const fname = clientData.fname;
    const lname = clientData.lname;
    const weight = document.getElementById("weight").value;
    const bodyfat = document.getElementById("bodyfat").value;
    const adherence = document.getElementById("adherence").value;
    const struggles = document.getElementById("struggles").value;
    const digestion = document.getElementById("digestion").value;
    const hunger = document.getElementById("hunger").value;
    const sleep = document.getElementById("sleep").value;
    const stressors = document.getElementById("stressors").value;
    const feelings = document.getElementById("feelings").value;
    const fileSelect = document.getElementById("checkinPhotos");
    const checkinPhotos = fileSelect.files;

    const formData = new FormData();
    for (let i = 0; i < fileSelect.files.length; i++) {
      formData.append("checkinPhotos", fileSelect.files[i]);
    }

    if (!weight || checkinPhotos.length < 1) {
      alert(
        `Please make sure to include at a minimum your weight and a checkin photo. Thank you!`
      );
    } else {
      setLoaded(false)
      const dman = new dataHelper();
      const response2 = await dman.uploadCheckinPhotos(formData);
      const result2 = await response2.json();
      const response = await dman.uploadCheckin(
        clientData._id,
        email,
        fname,
        lname,
        weight,
        bodyfat,
        adherence,
        struggles,
        digestion,
        hunger,
        sleep,
        stressors,
        feelings
      );
      const result = await response.json();
      alert(result.msg);
      await getData();
    }

    //  input validation
  };

  if (!loaded) {
    return <Loading />;
  } else if (loaded) {
    return (
      <div className="portalCheckinsModule">
        <h2>Checkins </h2>
        <p>
          Here you may complete your checkin forms when they are due. Please
          make sure to complete one before each and every checkin with your
          trainer so they have something to work with.
        </p>
        <strong>Checkin Required: {checkinRequired ? `Yes` : `No`}</strong>
        {checkinRequired ? (
          <>
            <h3>Checkin Form</h3>
            <label>What is your current weight?</label>
            <input type="text" id="weight" placeholder="180" />
            <label>What is your current bodyfat percentage if known?</label>
            <input type="text" id="bodyfat" placeholder="13%" />
            <label>From 1 - 10, how well did you stick to the plan?</label>
            <input type="email" id="adherence" placeholder="8" />
            <label>What were your struggles sticking to the plan?</label>
            <input
              type="text"
              id="struggles"
              placeholder="had an extra cheat meal.."
            />
            <label>
              How was your digestive health? Bad belly or bloat? Felt good?
            </label>
            <input type="text" id="digestion" placeholder="Felt good" />
            <label>How was your hunger level?</label>
            <input
              type="text"
              id="hunger"
              placeholder="Felt really hungry... I felt overly full"
            />
            <label>How many hours of sleep did you average per night?</label>
            <input type="number" id="sleep" placeholder="8" />
            <label>
              Did you have any external stressors that hindered your progress?
            </label>
            <input
              type="text"
              id="stressors"
              placeholder="Work has been hellish"
            />
            <label>How did you feel overall?</label>
            <input
              type="text"
              id="feelings"
              placeholder="Felt really strong during workouts... felt tired and weak..."
            />
            <label>Please submit a checkin photo</label>
            <input
              name="checkinPhotos"
              id="checkinPhotos"
              type="file"
              accept="image/*"
              multiple
            />
            <button onClick={submitCheckinForm}>Submit Checkin Form</button>
          </>
        ) : (
          <strong>Come back soon!</strong>
        )}
      </div>
    );
  }
};
export default PortalCheckinsModule;
