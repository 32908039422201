import React, { useEffect, useState } from "react";
import WorkoutRow from "./WorkoutRow";

const WorkoutList = (props) => {
  const client = props.client;
  const workoutList = props.workoutList;



  const solve = () => {
    const length = props.count;
    const dataObj = props.data;

    let collection = [];
    for (let i = 0; i < length; i++) {
      collection[i] = (
        <WorkoutRow
          workoutList={workoutList}
          client={client}
          wid={"wid" + props.day}
          wd={dataObj.workouts[i]}
          sid={"sid" + props.day}
          sd={dataObj.sets[i]}
          rid={"rid" + props.day}
          rd={dataObj.reps[i]}
        />
      );
    }
    return collection;
  };

  return <>{solve()}</>;
};

export default WorkoutList;
