import React, { createContext, useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";
import userHelper from "../helpers/userHelper";
import WorkoutDay from "./parts/WorkoutDay";

const PortalWorkoutPlanningModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [selectedClient, setSelectedClient] = useState(``);
  const [clientList, setClientList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [workoutPlan, setWorkoutPlan] = useState(null);
  const [workoutList, setWorkoutList] = useState(undefined);
  const [previousState, setPreviousState] = useState(null);
  const [lastCopyTarget, setLastCopyTarget] = useState(null);
  const [lastCopyState, setLastCopyState] = useState(null);
  const [undoButtonLoader, setUndoButtonLoader] = useState(false);
  const [undoCopyLoader, setUndoCopyLoader] = useState(false);
  const [clientView, setClientView] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);


  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectclient`).value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const fetchData = async () => {
    const uman = new userHelper();
    const response = await uman.getClientList();
    const result = await response.json();
    const response3 = await uman.getFullList();
    const result3 = await response3.json();
    const dman = new dataHelper();
    const response2 = await dman.getWorkoutList();
    const result2 = await response2.json();
    if (
      result.status != `ok` ||
      result2.status != `ok` ||
      result3.status != `ok`
    ) {
      nav(`/login`);
    }
    setWorkoutList(result2.data);
    setClientList(result.data);
    setFullList(result3.data);
    setLoaded(true);
  };

  const fetchWorkoutPlan = async () => {
    const id = document.querySelector(`#selectclient`).value;
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.getWorkoutPlan(id);
    const result = await response.json();
    console.log(result.data);
    if (result.status != `ok`) {
      nav(`/login`);
    } else {
      setWorkoutPlan(result.data);
      setSelectedClient(id);
      setLoaded(true);
    }
  };

  const populateClientBox = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag(client._id, client.fname, client.lname));
    });
    return payload;
  };

  const populateClientBoxAll = () => {
    let payload = [];
    fullList.forEach((client) => {
      payload.push(genTag(client._id, client.fname, client.lname));
    });
    return payload;
  };

  const copyPlan = async () => {
    const from = document.querySelector(`#selectfrom`).value;
    const to = document.querySelector(`#selectto`).value;
    setLastCopyTarget(to);
    const dman = new dataHelper();
    const response2 = await dman.getWorkoutPlan(to);
    const result2 = await response2.json();
    setLastCopyState({
      monday: JSON.parse(result2.data.monday),
      tuesday: JSON.parse(result2.data.tuesday),
      wednesday: JSON.parse(result2.data.wednesday),
      thursday: JSON.parse(result2.data.thursday),
      friday: JSON.parse(result2.data.friday),
      saturday: JSON.parse(result2.data.saturday),
      sunday: JSON.parse(result2.data.sunday),
    });
    const response = await dman.copyWorkoutPlan(from, to);
    const result = await response.json();
    alert(result.msg);
    fetchWorkoutPlan();
  };

  const genTag = (id, fname, lname) => {
    return (
      <option value={id}>
        {fname} {lname}
      </option>
    );
  };

  const saveWorkoutPlan = async () => {
    if (!!workoutPlan) {
      setPreviousState({
        monday: JSON.parse(workoutPlan.monday),
        tuesday: JSON.parse(workoutPlan.tuesday),
        wednesday: JSON.parse(workoutPlan.wednesday),
        thursday: JSON.parse(workoutPlan.thursday),
        friday: JSON.parse(workoutPlan.friday),
        saturday: JSON.parse(workoutPlan.saturday),
        sunday: JSON.parse(workoutPlan.sunday),
      });
    }

    let monday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let tuesday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let wednesday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let thursday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let friday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let saturday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let sunday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    const mondayMG = document.getElementById("mgMonday");
    monday.musclegroup = mondayMG.value;
    const mondayWorkouts = document.getElementsByName("widMonday");
    mondayWorkouts.forEach((workout) => {
      monday.workouts.push(workout.value);
    });
    const mondaySets = document.getElementsByName("sidMonday");
    mondaySets.forEach((set) => {
      monday.sets.push(set.value);
    });
    const mondayReps = document.getElementsByName("ridMonday");
    mondayReps.forEach((reps) => {
      monday.reps.push(reps.value);
    });
    const tuesdayMG = document.getElementById("mgTuesday");
    tuesday.musclegroup = tuesdayMG.value;
    const tuesdayWorkouts = document.getElementsByName("widTuesday");
    tuesdayWorkouts.forEach((workout) => {
      tuesday.workouts.push(workout.value);
    });
    const tuesdaySets = document.getElementsByName("sidTuesday");
    tuesdaySets.forEach((set) => {
      tuesday.sets.push(set.value);
    });
    const tuesdayReps = document.getElementsByName("ridTuesday");
    tuesdayReps.forEach((reps) => {
      tuesday.reps.push(reps.value);
    });
    const wednesdayMG = document.getElementById("mgWednesday");
    wednesday.musclegroup = wednesdayMG.value;
    const wednesdayWorkouts = document.getElementsByName("widWednesday");
    wednesdayWorkouts.forEach((workout) => {
      wednesday.workouts.push(workout.value);
    });
    const wednesdaySets = document.getElementsByName("sidWednesday");
    wednesdaySets.forEach((set) => {
      wednesday.sets.push(set.value);
    });
    const wednesdayReps = document.getElementsByName("ridWednesday");
    wednesdayReps.forEach((reps) => {
      wednesday.reps.push(reps.value);
    });
    const thursdayMG = document.getElementById("mgThursday");
    thursday.musclegroup = thursdayMG.value;
    const thursdayWorkouts = document.getElementsByName("widThursday");
    thursdayWorkouts.forEach((workout) => {
      thursday.workouts.push(workout.value);
    });
    const thursdaySets = document.getElementsByName("sidThursday");
    thursdaySets.forEach((set) => {
      thursday.sets.push(set.value);
    });
    const thursdayReps = document.getElementsByName("ridThursday");
    thursdayReps.forEach((reps) => {
      thursday.reps.push(reps.value);
    });
    const fridayMG = document.getElementById("mgFriday");
    friday.musclegroup = fridayMG.value;
    const fridayWorkouts = document.getElementsByName("widFriday");
    fridayWorkouts.forEach((workout) => {
      friday.workouts.push(workout.value);
    });
    const fridaySets = document.getElementsByName("sidFriday");
    fridaySets.forEach((set) => {
      friday.sets.push(set.value);
    });
    const fridayReps = document.getElementsByName("ridFriday");
    fridayReps.forEach((reps) => {
      friday.reps.push(reps.value);
    });
    const saturdayMG = document.getElementById("mgSaturday");
    saturday.musclegroup = saturdayMG.value;
    const saturdayWorkouts = document.getElementsByName("widSaturday");
    saturdayWorkouts.forEach((workout) => {
      saturday.workouts.push(workout.value);
    });
    const saturdaySets = document.getElementsByName("sidSaturday");
    saturdaySets.forEach((set) => {
      saturday.sets.push(set.value);
    });
    const saturdayReps = document.getElementsByName("ridSaturday");
    saturdayReps.forEach((reps) => {
      saturday.reps.push(reps.value);
    });
    const sundayMG = document.getElementById("mgSunday");
    sunday.musclegroup = sundayMG.value;
    const sundayWorkouts = document.getElementsByName("widSunday");
    sundayWorkouts.forEach((workout) => {
      sunday.workouts.push(workout.value);
    });
    const sundaySets = document.getElementsByName("sidSunday");
    sundaySets.forEach((set) => {
      sunday.sets.push(set.value);
    });
    const sundayReps = document.getElementsByName("ridSunday");
    sundayReps.forEach((reps) => {
      sunday.reps.push(reps.value);
    });
    const d = new dataHelper();
    const response = await d.updateWorkoutPlan(
      selectedClient,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    );
    const result = await response.json();
    if ((result.status = `ok`)) {
      alert(`Workout plan updated successfully!`);
    } else {
      alert(result.msg);
    }
    fetchWorkoutPlan(selectedClient);
  };

  const assignBlankPlan = async () => {
    if (!!workoutPlan) {
      setPreviousState({
        monday: JSON.parse(workoutPlan.monday),
        tuesday: JSON.parse(workoutPlan.tuesday),
        wednesday: JSON.parse(workoutPlan.wednesday),
        thursday: JSON.parse(workoutPlan.thursday),
        friday: JSON.parse(workoutPlan.friday),
        saturday: JSON.parse(workoutPlan.saturday),
        sunday: JSON.parse(workoutPlan.sunday),
      });
    }
    let monday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let tuesday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let wednesday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let thursday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let friday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let saturday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    let sunday = {
      musclegroup: "",
      workouts: [],
      sets: [],
      reps: [],
    };
    const d = new dataHelper();
    const response = await d.updateWorkoutPlan(
      selectedClient,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    );
    const result = await response.json();
    const { msg } = result;
    alert(msg);
    fetchWorkoutPlan();
  };

  const undoAction = async () => {
    setUndoButtonLoader(true);
    if (!!previousState) {
      const d = new dataHelper();
      const response = await d.updateWorkoutPlan(
        selectedClient,
        previousState.monday,
        previousState.tuesday,
        previousState.wednesday,
        previousState.thursday,
        previousState.friday,
        previousState.saturday,
        previousState.sunday
      );
      const result = await response.json();
      if ((result.status = `ok`)) {
        alert(`Workout Plan reverted`);
      } else {
        alert(result.msg);
      }
      fetchWorkoutPlan(selectedClient);
    } else {
      alert("There is no action to revert");
    }
    setUndoButtonLoader(true);
  };

  const undoCopy = async () => {
    setUndoCopyLoader(true);
    if (!!lastCopyState) {
      const d = new dataHelper();
      const response = await d.updateWorkoutPlan(
        lastCopyTarget,
        lastCopyState.monday,
        lastCopyState.tuesday,
        lastCopyState.wednesday,
        lastCopyState.thursday,
        lastCopyState.friday,
        lastCopyState.saturday,
        lastCopyState.sunday
      );
      const result = await response.json();
      if ((result.status = `ok`)) {
        alert(`Workout Plan reverted`);
      } else {
        alert(result.msg);
      }
      fetchWorkoutPlan(selectedClient);
    } else {
      alert("There is no action to revert");
    }
    setUndoCopyLoader(false);
  };

  const changeView = (e) => {
    setViewLoader(true)
    setClientView(e)
    setTimeout(()=>{
      setViewLoader(false)
    },300)
  }

  if (loaded) {
    return (
      <div className="portalWorkoutPlanningModule">
        <h2>Workout Planning</h2>
        <p>
          Use the Workout Planning module to create, view, edit, and assign
          workout plans to clients.
        </p>
        <h3>Select Client</h3>
        <select id="selectclient" onChange={fetchWorkoutPlan}>
          <option value="">SELECT A CLIENT</option>
          {populateClientBox()}
        </select>
        <button onClick={saveWorkoutPlan}>Save Workout Plan</button>
        <button onClick={assignBlankPlan}>Assign Blank Plan</button>
        <button onClick={undoAction}>
          {undoButtonLoader ? <Loading /> : "Undo"}
        </button>
        <h3>Plan Templates</h3>
        <p>Copy from:</p>
        <select id="selectfrom">
          <option value="">SELECT A CLIENT</option>
          {populateClientBoxAll()}
        </select>
        <p>Copy to:</p>
        <select id="selectto">
          <option value="">SELECT A CLIENT</option>
          {populateClientBox()}
        </select>
        <button onClick={copyPlan}>Copy Workout Plan</button>
        <button onClick={undoCopy}>
          {undoCopyLoader ? <Loading /> : "Undo"}
        </button>
        <h3>Workout Plan</h3>
        <label htmlFor="clientView">Client View</label>
        <select id="clientView" name="clientView" onChange={(e)=>{changeView(e.target.value)}}>
          <option value="false">False</option>
          <option value="true">True</option>
        </select>
        {workoutPlan && !viewLoader ? (
          <div className="display">
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Monday"
              data={workoutPlan.monday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Tuesday"
              data={workoutPlan.tuesday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Wednesday"
              data={workoutPlan.wednesday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Thursday"
              data={workoutPlan.thursday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Friday"
              data={workoutPlan.friday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Saturday"
              data={workoutPlan.saturday}
            />
            <WorkoutDay
              workoutList={workoutList}
              client={clientView === 'true' ? true : false}
              day="Sunday"
              data={workoutPlan.sunday}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalWorkoutPlanningModule;
