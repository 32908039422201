export default class OLDdataHelper {
  async tryLogin(email, pass) {
    try {
      let response = await fetch("/api/u/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email: email,
          password: pass,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async queryUser() {
    try {
      let response = await fetch("/api/u/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query user",
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async queryUserInfo(id) {
    try {
      let response = await fetch("/api/u/query", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          id: id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async deleteUser(id) {
    try {
      let response = await fetch("/api/u/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Force Reset Password",
          id: id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async forceResetPassword(id) {
    try {
      let response = await fetch("/api/u/forceresetpassword", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Force Reset Password",
          id: id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async updateUserInfo(id, fname, lname, email, phone, address) {
    try {
      let response = await fetch("/api/u/updateuserinfo", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          id: id,
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
          address: address,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async changePass(id, oldpass, newpass) {
    try {
      let response = await fetch("/api/u/changepassword", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          id: id,
          password: oldpass,
          newpassword: newpass,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async assignCheckinForm(id) {
    try {
      let response = await fetch("/api/u/checkinform/assign", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          id: id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async logOut() {
    try {
      let response = await fetch("/api/u/logout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Logout",
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getWorkoutPlan(id) {
    try {
      let response = await fetch("/api/u/workoutplan/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query user",
          id: id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateWorkoutPlan(
    id,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  ) {
    try {
      let response = await fetch("/api/u/workoutplan/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query user",
          id: id,
        }),
      });
      let response2 = await fetch("/api/u/workoutplan/add", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Submit Workout Plan",
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          id: id,
        }),
      });
      return response2;
    } catch (error) {
      return error;
    }
  }
  async updateNutritionPlan(id, notes, macros, meals, fats, plan) {
    try {
      let response = await fetch("/api/u/nutritionplan/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Delete nutrition plan",
          id: id,
        }),
      });
      let response2 = await fetch("/api/u/nutritionplan/add", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Add nutrition plan",
          id: id,
          notes: notes,
          macros: macros,
          meals: meals,
          fats: fats,
          plan: plan,
        }),
      });
      return response2;
    } catch (error) {
      return error;
    }
  }
  async getNutritionPlan(id) {
    try {
      let response = await fetch("/api/u/nutritionplan/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query user",
          id: id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getFirstCheckinForm(email) {
    try {
      let response = await fetch("/api/u/firstcheckinform/getone", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query user",
          email: email,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async submitNewClientForm(
    fname,
    lname,
    email,
    phone,
    height,
    weight,
    age,
    natural,
    othercoach,
    currentseason,
    lastshow,
    goal,
    timeline,
    ready
  ) {
    try {
      let response = await fetch("/api/u/newclientform/add", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
          height: height,
          weight: weight,
          age: age,
          natural: natural,
          othercoach: othercoach,
          currentseason: currentseason,
          lastshow: lastshow,
          goal: goal,
          timeline: timeline,
          ready: ready,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getNewNewClientForms() {
    try {
      let response = await fetch("/api/u/newclientform/getnew", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query unprocessed New Client Forms",
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getAllClients() {
    try {
      let response = await fetch("/api/u/getall", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query all clients",
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async processNewClientForm(email) {
    try {
      let response = await fetch("/api/u/newclientform/process", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Process New Client Forms",
          email: email,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async rejectNewClientForm(email) {
    try {
      let response = await fetch("/api/u/newclientform/reject", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Reject unprocessed New Client Form",
          email: email,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async processCheckinForm(_id) {
    try {
      let response = await fetch("/api/u/checkinform/process", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Process Checkin Form",
          _id: _id,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async addCheckinForm(
    email,
    fname,
    lname,
    weight,
    bodyfat,
    adherence,
    struggles,
    digestion,
    hunger,
    sleep,
    stressors,
    feelings
  ) {
    try {
      let response = await fetch("/api/u/checkinform/add", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email: email,
          fname: fname,
          lname: lname,
          weight: weight,
          bodyfat: bodyfat,
          adherence: adherence,
          struggles: struggles,
          digestion: digestion,
          hunger: hunger,
          sleep: sleep,
          stressors: stressors,
          feelings: feelings,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getNewCheckinForms() {
    try {
      let response = await fetch("/api/u/checkinform/getnew", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          msg: "Query unprocessed Checkin Forms",
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async triggerPasswordReset(email) {
    try {
      let response = await fetch("/mailer/forgotpassword", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email: email,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async resetPassword(id, password) {
    if (password) {
      try {
        let response = await fetch("/api/u/resetpassword", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            id: id,
            password: password,
          }),
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  }
}
