import React, { useState } from "react";
import { Link } from "react-router-dom";

const ClientHeader = ({ userRole }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const logOut = () => {
    document.cookie = `tbt="";max-age=0;path='/'`;
    window.location.replace("/login");
  };

  const toggleMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  if (userRole == "2") {
    return (
      <>
        <nav className="clientHeader">
          <div className="mobile">
            <img
              src="/assets/photos/logo-large-inverted.png"
              onClick={toggleMenu}
            />
          </div>
          <div className="desktop">
            <img src="/assets/photos/logo-large-inverted.png" />
            <button onClick={logOut}>LOGOUT</button>
          </div>
        </nav>
        <div
          id="menu"
          className={
            menuOpen ? `mobileSlideOut menuOpen` : `mobileSlideOut menuClosed`
          }
          onClick={toggleMenu}
        >
          <label>Dashboard</label>
          <Link to="/client">Home</Link>
          <Link to="/client/account">Account</Link>
          <Link to="/client/workouttracker">Workout Tracker</Link>
          <Link to="/client/workoutplan">Workout Plan</Link>
          <Link to="/client/nutritionplan">Nutrition Plan</Link>
          <Link to="/client/tutorials">Workout Tutorials</Link>
          <Link to="/client/checkins">Checkin Forms</Link>
          <Link to="/client/checkinscheduling">Schedule a Checkin</Link>
          <label>Employee Tools</label>
          <Link to="/client/newcheckins">New Checkins</Link>
          <Link to="/client/oldcheckins">Old Checkins</Link>
          <Link to="/client/workoutplanning">Workout Planning</Link>
          <Link to="/client/nutritionplanning">Nutrition Planning</Link>
          <Link to="/client/workoutmanager">Workout Manager</Link>
          <Link to="/client/clientnotes">Client Notes</Link>
          <label>Admin Tools</label>
          <Link to="/client/ecom">E-Commerce</Link>            
          <Link to="/client/schedulingmanager">Scheduling Manager</Link>
          <Link to="/client/employeedatabase">Employee Database</Link>
          <Link to="/client/employeetasking">Employee Tasking</Link>
          <Link to="/client/clientdatabase">Client Database</Link>
          <label>Log Out</label>
          <button onClick={logOut}>Log Out</button>
        </div>
      </>
    );
  } else if (userRole == "1") {
    return (
      <>
        <nav className="clientHeader">
          <div className="mobile">
            <img
              src="/assets/photos/logo-large-inverted.png"
              onClick={toggleMenu}
            />
          </div>
          <div className="desktop">
            <img src="/assets/photos/logo-large-inverted.png" />
            <button onClick={logOut}>LOGOUT</button>
          </div>
        </nav>
        <div
          className={menuOpen ? `mobileSlideOut menuOpen` : `mobileSlideOut`}
          onClick={toggleMenu}
        >
          <label>Dashboard</label>
          <Link to="/client">Home</Link>
          <Link to="/client/account">Account</Link>
          <Link to="/client/workouttracker">Workout Tracker</Link>
          <Link to="/client/workoutplan">Workout Plan</Link>
          <Link to="/client/nutritionplan">Nutrition Plan</Link>
          <Link to="/client/tutorials">Workout Tutorials</Link>
          <Link to="/client/checkins">Checkin Forms</Link>
          <Link to="/client/checkinscheduling">Schedule a Checkin</Link>
          <label>Employee Tools</label>
          <Link to="/client/newcheckins">New Checkins</Link>
          <Link to="/client/oldcheckins">Old Checkins</Link>
          <Link to="/client/workoutplanning">Workout Planning</Link>
          <Link to="/client/nutritionplanning">Nutrition Planning</Link>
          <Link to="/client/workoutmanager">Workout Manager</Link>
          <Link to="/client/clientnotes">Client Notes</Link>

          <label>Log Out</label>
          <button onClick={logOut}>Log Out</button>
        </div>
      </>
    );
  } else if (userRole == "0") {
    return (
      <>
        <nav className="clientHeader">
          <div className="mobile">
            <img
              src="/assets/photos/logo-large-inverted.png"
              onClick={toggleMenu}
            />
          </div>

          <div className="desktop">
            <img src="/assets/photos/logo-large-inverted.png" />
            <button onClick={logOut}>LOGOUT</button>
          </div>
        </nav>
        <div
          className={
            menuOpen ? `mobileSlideOut menuOpen` : `mobileSlideOut menuClosed`
          }
          onClick={toggleMenu}
        >
          <label>Dashboard</label>
          <Link to="/client">Home</Link>
          <Link to="/client/account">Account</Link>
          <Link to="/client/workouttracker">Workout Tracker</Link>
          <Link to="/client/workoutplan">Workout Plan</Link>
          <Link to="/client/nutritionplan">Nutrition Plan</Link>
          <Link to="/client/tutorials">Workout Tutorials</Link>
          <Link to="/client/checkins">Checkin Forms</Link>
          <Link to="/client/checkinscheduling">Schedule a Checkin</Link>
          <label>Log Out</label>
          <Link to="/login">
            <button onClick={logOut}>Log Out</button>
          </Link>
        </div>
      </>
    );
  }
};

export default ClientHeader;
