import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useNavigate } from "react-router";
import userHelper from "../helpers/userHelper";

const PortalAccountModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    const helper = new userHelper();
    const response = await helper.getClientData();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    } else {
      setCurrentUser(result.data);
    }
    setLoaded(true);
  }

  const changeEmail = async () => {
    const oldEmail = document.querySelector(`#oldEmail`).value
    const newEmail1 = document.querySelector(`#newEmail1`).value
    const newEmail2 = document.querySelector(`#newEmail2`).value
    setLoaded(false)
    if(newEmail1 == newEmail2){
      if(oldEmail){
        const uman = new userHelper()
        const response = await uman.clientUpdateEmail(newEmail1)
        const result = await response.json()
        alert(result.msg)
      } else {
        alert(`Please provide your current email`)
      }
    } else {
      alert(`New emails must match`)
    }
    fetchData()
  };

  const changePassword = async () => {
    const oldPass = document.querySelector(`#oldPass`).value
    const newPass1 = document.querySelector(`#newPass1`).value
    const newPass2 = document.querySelector(`#newPass2`).value
    setLoaded(false)
    if(newPass1 == newPass2){
      if(oldPass){
        const uman = new userHelper()
        const response = await uman.clientUpdatePassword(newPass1,oldPass)
        const result = await response.json()
        alert(result.msg)
      } else {
        alert(`Please provide your current password`)
      }
    } else {
      alert(`New passwords must match`)
    }
    fetchData()
  };

  const requestCancelation = async () => {
    setLoaded(false)
    const u = new userHelper()
    const response = await u.requestCancelation()
    const result = await response.json()
    const {msg} = result
    alert(msg)
    setLoaded(true)
  };

  if (!loaded) {
    return <Loading />;
  } else if (loaded && currentUser) {
    return (
      <div className="portalAccountModule">
        <h2>Account </h2>
        <h3>Basic Information</h3>
        <div className="rowWrapper">
          <strong>First Name:</strong>
          <p>{currentUser.fname}</p>
        </div>
        <div className="rowWrapper">
          <strong>Last Name:</strong>
          <p>{currentUser.lname}</p>
        </div>
        <div className="rowWrapper">
          <strong>Email:</strong>
          <p>{currentUser.email}</p>
        </div>
        <div className="rowWrapper">
          <strong>Phone:</strong>
          <p>{currentUser.phone}</p>
        </div>
        <div className="rowWrapper">
          <strong>Address:</strong>
          <p>{currentUser.address}</p>
        </div>
        <h3>Change Email</h3>
        <input
          id="oldEmail"
          type="email"
          placeholder="enter your current email"
          required
        />
        <input
          id="newEmail1"
          type="email"
          placeholder="enter your new email"
          required
        />
        <input
          id="newEmail2"
          type="email"
          placeholder="confirm your current email"
          required
        />
        <button onClick={changeEmail}>CHANGE EMAIL</button>
        <h3>Change Password</h3>
        <input
          id="oldPass"
          type="password"
          placeholder="enter your current password"
          required
        />
        <input
          id="newPass1"
          type="password"
          placeholder="enter your new password"
          required
        />
        <input
          id="newPass2"
          type="password"
          placeholder="confirm your current password"
          required
        />
        <button onClick={changePassword}>CHANGE PASSWORD</button>
        <h3>Cancel and Remove Account</h3>
        <p>
          To cancel and remove your account, you must click below to submit a
          cancelation request. A trainer will reach out and confirm your
          cancelation.
        </p>
        <button onClick={requestCancelation}>REQUEST CANCELATION</button>
      </div>
    );
  } else {
    
  }
};

export default PortalAccountModule;
