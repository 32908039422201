import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";
import userHelper from "../helpers/userHelper";
import CheckinForms from "./parts/CheckinForms";

const PortalNewCheckinsModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [activeCheckins, setActiveCheckins] = useState([]);
  const [selectedCheckin, setSelectedCheckin] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [workoutList, setWorkoutList] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setActiveCheckins([]);
    const dman = new dataHelper();
    const response = await dman.getNewCheckins();
    const result = await response.json();
    const uman = new userHelper();
    const res2 = await uman.getClientList();
    const result2 = await res2.json();
    const response3 = await dman.getWorkoutList();
    const result3 = await response3.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    setWorkoutList(result3.data);
    setClientList(result2.data);
    setActiveCheckins(result.data ? result.data : null);
    setLoaded(true);
  };

  const populateCheckins = () => {
    let payload = [];
    activeCheckins.forEach((form) => {
      payload.push(
        <CheckinForms
          fname={form.fname}
          lname={form.lname}
          email={form.email}
          weight={form.weight}
          bodyfat={form.bodyfat}
          adherence={form.adherence}
          struggles={form.struggles}
          digestion={form.digestion}
          hunger={form.hunger}
          sleep={form.sleep}
          stressors={form.stressors}
          feelings={form.feelings}
          date={form.date}
          _id={form._id}
          belongsToID={form.belongsToID}
          refreshHandler={fetchData}
          workoutList={workoutList}
        />
      );
    });
    return payload;
  };

  const populateSelectBox = () => {
    let payload = [];
    activeCheckins.forEach((checkin) => {
      payload.push(genTag(checkin.fname, checkin.lname, checkin._id));
    });
    return payload;
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const selectCheckin = () => {
    //  search activeCheckins to find the one selected
    const query = document.querySelector(`#selectedCheckin`).value;
    let checkinData;
    activeCheckins.forEach((checkin) => {
      if (query == checkin._id) {
        checkinData = checkin;
      }
    });
    setSelectedCheckin(checkinData);
  };

  const handleSelectedCheckin = () => {
    return genTag(
      selectedCheckin.fname,
      selectedCheckin.lname,
      selectedCheckin.email,
      selectedCheckin.weight,
      selectedCheckin.bodyfat,
      selectedCheckin.adherence,
      selectedCheckin.struggles,
      selectedCheckin.digestion,
      selectedCheckin.hunger,
      selectedCheckin.sleep,
      selectedCheckin.stressors,
      selectedCheckin.feelings,
      selectedCheckin._id
    );
  };

  const populateClientSelect = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag(client.fname, client.lname, client._id));
    });
    return payload;
  };

  const handleClientSelect = () => {
    setSelectedClient(document.querySelector(`#selectedClient`).value);
  };

  const sendCheckin = async () => {
    setLoaded(false);
    const d = new dataHelper();
    const resp = await d.sendCheckin(selectedClient);
    const res = await resp.json();
    const { msg } = res;
    alert(msg);
    setLoaded(true);
  };

  if (!loaded) {
    return <Loading />;
  } else if (loaded) {
    return (
      <div className="portalNewCheckinsModule">
        <h2>New Checkins</h2>
        <p>
          See your new checkins here. Process them as you complete your in
          person over remote checkins with your clients.
        </p>
        <h3>Send Checkins</h3>
        <p>
          Checkin forms are automatically sent based on the time selected when
          the form is processed. However, In situations where manually sending a
          checkin form to clients makes sense, use this feature.
        </p>
        <select id="selectedClient" onChange={handleClientSelect}>
          <option value="">SELECT A CLIENT</option>
          {populateClientSelect()}
        </select>
        <button onClick={sendCheckin}>Send Checkin Form</button>
        <h3>Active Checkins</h3>
        {activeCheckins ? (
          <>
            <strong>Total:{activeCheckins.length}</strong>
            <div className="forms">{populateCheckins()}</div>
          </>
        ) : (
          <strong>Check back later for more!</strong>
        )}
      </div>
    );
  }
};

export default PortalNewCheckinsModule;
