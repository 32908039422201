import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";

const PortalWorkoutManager = () => {
  const [loaded, setLoaded] = useState(false);
  const [workoutList, setWorkoutList] = useState([]);
  const [selectedWorkout, setSelectedWorkout] = useState({ _id: "" });
  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectworkout`).defaultValue =
        selectedWorkout._id;
    } catch (err) {}
  }, [loaded]);

  const fetchData = async () => {
    const dman = new dataHelper();
    const response = await dman.getWorkoutList();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    } else {
      setWorkoutList(result.data);
    }
    setLoaded(true);
  };

  const populateSelectBox = () => {
    let payload = [];
    workoutList.forEach((workout) => {
      payload.push(genTag(workout._id, workout.name));
    });
    return payload;
  };

  const genTag = (id, name) => {
    return <option value={id}>{name}</option>;
  };

  const saveWorkout = async () => {
    const name = document.querySelector(`#name`).value;
    const notes = document.querySelector(`#notes`).value;
    const videolink = document.querySelector(`#videolink`).value;
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.updateWorkout(
      selectedWorkout._id,
      name,
      notes,
      videolink,
      null
    );
    const result = await response.json();
    alert(result.msg);

    const response2 = await dman.getWorkout(selectedWorkout._id);
    const result2 = await response2.json();
    const { data } = result2;
    setSelectedWorkout(data);
    fetchData();
  };

  const removeWorkout = () => {
  };

  const saveNewWorkout = async () => {
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.createWorkout(
      document.getElementById(`name2`).value,
      document.getElementById(`notes2`).value,
      document.getElementById(`videolink2`).value
    );
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    } else {
      alert(result.msg);
      fetchData();
    }
  };

  const populateFormData = async () => {
    setLoaded(false);
    const target = document.getElementById(`selectworkout`).value;
    const dman = new dataHelper();
    const response = await dman.getWorkout(target);
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    } else {
      setSelectedWorkout(result.data);
      setLoaded(true);
    }
  };

  if (loaded) {
    return (
      <div className="portalWorkoutManager">
        <h2>Workout Manager</h2>
        <p>
          Use the Workout Manager module to create workouts and assign
          descriptions and tutorial links. Clients are able to click on the
          workout and see what you put here.
        </p>
        <h3>Edit Workouts</h3>
        <select id="selectworkout" onChange={populateFormData}>
          <option defaultValue="">SELECT A WORKOUT TO EDIT</option>
          {populateSelectBox()}
        </select>
        <input
          id="name"
          type="text"
          placeholder="enter workout name"
          defaultValue={selectedWorkout.name}
        />
        <textarea
          id="notes"
          type="text"
          placeholder="enter workout notes"
          defaultValue={selectedWorkout.notes}
        />
        <input
          id="videolink"
          type="text"
          placeholder="enter workout video link"
          defaultValue={selectedWorkout.videolink}
        />
        <button onClick={saveWorkout}>Save Workout</button>
        <button onClick={removeWorkout}>Remove Workout</button>
        <h3>Create Workouts</h3>
        <input id="name2" type="text" placeholder="enter workout name" />
        <textarea id="notes2" type="text" placeholder="enter workout notes" />
        <input
          id="videolink2"
          type="text"
          placeholder="enter workout video link"
        />
        <button onClick={saveNewWorkout}>Save New Workout</button>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalWorkoutManager;
