import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import userHelper from "../helpers/userHelper";

const PortalSchedulingModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [calendarLink, setCalendarLink] = useState(null);
  const [calendarLinkOnlineOnly, setCalendarLinkOnlineOnly] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const uman = new userHelper();
    const response = await uman.getAssignedCalendarLink();
    const response2 = await uman.getOnlineCalendarLink();
    const result = await response.json();
    const result2 = await response2.json();
    setCalendarLink(result.data ? result.data : null);
    setCalendarLinkOnlineOnly(result2.data ? result2.data : null);
    setLoaded(true);
  };

  const takeToCalendar = () => {
    window.open(calendarLink, "_blank").focus();
  };

  const takeToCalendarOnline = () => {
    window.open(calendarLinkOnlineOnly, "_blank").focus();
  };

  if (!loaded) {
    return <Loading />;
  } else if (loaded) {
    return (
      <div className="portalSchedulingModule">
        <h2>Schedule a Checkin</h2>
        <p>
          See your trainer's availability and book a checkin using this module.
        </p>
        {calendarLink ? (
          <>
            <h3>In Person Checkin</h3>
            <button onClick={takeToCalendar}>Schedule Checkin</button>
            <p>
              Cant make your appointment? Please notify us at least 48 hours in
              advance of any cancelations or reschedules in order to avoid a 30$
              no-show fee. Thank you!
            </p>
          </>
        ) : (
          <>
            <h3>In Person Checkin</h3>
            <strong>
              Your assigned trainer does not provide in person checkins.
            </strong>
          </>
        )}
        {calendarLinkOnlineOnly ? (
          <>
            <h3>Online Only Checkin</h3>
            <button onClick={takeToCalendarOnline}>Schedule Checkin</button>
            <p>
              Cant make your appointment? Please notify us at least 48 hours in
              advance of any cancelations or reschedules in order to avoid a 30$
              no-show fee. Thank you!
            </p>
          </>
        ) : (
          <>
            <h3>Online Only Checkins</h3>
            <strong>
              Your assigned trainer does not provide online-only checkins.
            </strong>
          </>
        )}
      </div>
    );
  }
};

export default PortalSchedulingModule;
