import React, { createContext, useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import MealList from "./parts/MealList";
import { useNavigate } from "react-router";

export const NutritionClientContext = createContext(undefined);

const PortalNutritionPlanModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [nutritionPlan, setNutritionPlan] = useState(null);
  const [clientContext, setClientContext] = useState(true);
  const nav = useNavigate()
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.getCurrentNutritionPlan();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setNutritionPlan(data);
    setLoaded(true);
  };

  if (loaded) {
    return (
      <div className="portalNutritionPlanModule">
        <h2>Nutrition Plan</h2>
        <p>See your nutrition plan here</p>
        {nutritionPlan ? (
          <>
            <h3>Notes</h3>
            <textarea readOnly>{nutritionPlan.notes}</textarea>
            <h3>General Information</h3>
            <div className="top">
              <div className="section">
                <h3>Meals</h3>
                <div className="label">
                  <label>Meals: </label>
                  <p>{JSON.parse(nutritionPlan.meals).quantity}</p>
                </div>
                <div className="label">
                  <label>Protein: </label>
                  <p>{JSON.parse(nutritionPlan.meals).proteinEach}</p>
                </div>
                <div className="label">
                  <label>Carbs: </label>
                  <p>{JSON.parse(nutritionPlan.meals).carbsEach}</p>
                </div>
                <div className="label">
                  <label>Fats: </label>
                  <p>{JSON.parse(nutritionPlan.meals).fatsEach}</p>
                </div>
              </div>
              <div className="section">
                <h3>Macros</h3>
                <div className="label">
                  <label>Protein: </label>
                  <p>{JSON.parse(nutritionPlan.macros).protein}</p>
                </div>
                <div className="label">
                  <label>Carbs: </label>
                  <p>{JSON.parse(nutritionPlan.macros).carbs}</p>
                </div>
                <div className="label">
                  <label>Fats: </label>
                  <p>{JSON.parse(nutritionPlan.macros).fats}</p>
                </div>
              </div>
              <div className="section">
                <h3>Fat Sources</h3>
                <div className="label">
                  <label>Oils: </label>
                  <p>{JSON.parse(nutritionPlan.fats).oils}</p>
                </div>
                <div className="label">
                  <label>Nuts: </label>
                  <p>{JSON.parse(nutritionPlan.fats).nuts}</p>
                </div>
                <div className="label">
                  <label>Nut Butters: </label>
                  <p>{JSON.parse(nutritionPlan.fats).nb}</p>
                </div>
                <div className="label">
                  <label>Avocado: </label>
                  <p>{JSON.parse(nutritionPlan.fats).avocado}</p>
                </div>
                <div className="label">
                  <label>Butter: </label>
                  <p>{JSON.parse(nutritionPlan.fats).butter}</p>
                </div>
              </div>
            </div>
            <h3>Meals</h3>
            <div className="mealList">
                <MealList
                  mealData={JSON.parse(nutritionPlan.plan)}
                  count={JSON.parse(nutritionPlan.meals).quantity}
                  clientContext={true}
                />
            </div>
          </>
        ) : (
          <strong>Please come back later! Your plan is not ready yet.</strong>
        )}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalNutritionPlanModule;
