import React, { useEffect, useState } from "react";
import ClientHeader from "../../comps/ClientHeader";
import ClientFooter from "../../comps/ClientFooter";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Loading from "../../comps/Loading";
import userHelper from "../../helpers/userHelper";

const ClientPortal = () => {
  const [loaded, setLoaded] = useState(false);
  const [userRole, setUserRole] = useState(undefined);

  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const uman = new userHelper();
    const response = await uman.getClientData();
    const result = await response.json();
    if (!result.data || !result || result.status != `ok`) {
      nav("/login");
    } else {
      const { data } = result;
      const { level } = data;
      setUserRole(level);
      setLoaded(true);
    }
  };

  if (!loaded) {
    return <Loading></Loading>;
  } else if (userRole == `2`) {
    return (
      <div className="app">
        <ClientHeader userRole={userRole} />
        <main className="clientMain">
          <aside>
            <label>Dashboard</label>
            <Link to="/client">Home</Link>
            <Link to="/client/account">Account</Link>
            <Link to="/client/workouttracker">Workout Tracker</Link>
            <Link to="/client/workoutplan">Workout Plan</Link>
            <Link to="/client/nutritionplan">Nutrition Plan</Link>
            <Link to="/client/tutorials">Workout Tutorials</Link>
            <Link to="/client/checkins">Checkin Forms</Link>
            <Link to="/client/checkinscheduling">Schedule a Checkin</Link>
            <label>Employee Tools</label>
            <Link to="/client/newcheckins">New Checkins</Link>
            <Link to="/client/oldcheckins">Old Checkins</Link>
            <Link to="/client/workoutplanning">Workout Planning</Link>
            <Link to="/client/nutritionplanning">Nutrition Planning</Link>
            <Link to="/client/workoutmanager">Workout Manager</Link>
            <Link to="/client/clientnotes">Client Notes</Link>
            <label>Admin Tools</label>
            <Link to="/client/ecom">E-Commerce</Link>            
            <Link to="/client/schedulingmanager">Scheduling Manager</Link>
            <Link to="/client/employeedatabase">Employee Database</Link>
            <Link to="/client/employeetasking">Employee Tasking</Link>
            <Link to="/client/clientdatabase">Client Database</Link>
          </aside>
          <section>
            <Outlet />
          </section>
        </main>
        <ClientFooter userRole={userRole} />
      </div>
    );
  } else if (userRole == `1`) {
    return (
      <div className="app">
        <ClientHeader userRole={userRole} />
        <main className="clientMain">
          <aside>
            <label>Dashboard</label>
            <Link to="/client">Home</Link>
            <Link to="/client/account">Account</Link>
            <Link to="/client/workouttracker">Workout Tracker</Link>
            <Link to="/client/workoutplan">Workout Plan</Link>
            <Link to="/client/nutritionplan">Nutrition Plan</Link>
            <Link to="/client/tutorials">Workout Tutorials</Link>
            <Link to="/client/checkins">Checkin Forms</Link>
            <Link to="/client/checkinscheduling">Schedule a Checkin</Link>
            <label>Employee Tools</label>
            <Link to="/client/newcheckins">New Checkins</Link>
            <Link to="/client/oldcheckins">Old Checkins</Link>
            <Link to="/client/workoutplanning">Workout Planning</Link>
            <Link to="/client/nutritionplanning">Nutrition Planning</Link>
            <Link to="/client/workoutmanager">Workout Manager</Link>
            <Link to="/client/clientnotes">Client Notes</Link>
          </aside>
          <section>
            <Outlet />
          </section>
        </main>
        <ClientFooter userRole={userRole} />
      </div>
    );
  } else if (userRole == `0`) {
    return (
      <div className="app">
        <ClientHeader userRole={userRole} />
        <main className="clientMain">
          <aside>
            <label>Dashboard</label>
            <Link to="/client">Home</Link>
            <Link to="/client/account">Account</Link>
            <Link to="/client/workouttracker">Workout Tracker</Link>
            <Link to="/client/workoutplan">Workout Plan</Link>
            <Link to="/client/nutritionplan">Nutrition Plan</Link>
            <Link to="/client/tutorials">Workout Tutorials</Link>
            <Link to="/client/checkins">Checkin Forms</Link>
            <Link to="/client/checkinscheduling">Schedule a Checkin</Link>
          </aside>
          <section>
            <Outlet />
          </section>
        </main>
        <ClientFooter userRole={userRole} />
      </div>
    );
  }
};
export default ClientPortal;
