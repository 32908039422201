import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Layout from "../shared/Layout";

import "./styles/client.sass"
import "./styles/general.sass"

const root = document.getElementById("root");
// ReactDOMClient.createRoot(root).render(
//   <BrowserRouter>
//     <Layout />
//   </BrowserRouter>
// );
ReactDOMClient.hydrateRoot(
  root,
  <BrowserRouter>
    <Layout />
  </BrowserRouter>
);
