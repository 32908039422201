import React, { useEffect, useState } from "react";
import userHelper from "../helpers/userHelper";
import dataHelper from "../helpers/dataHelper";

const PortalClientNotes = () => {
  const [loaded, setLoaded] = useState(false);
  const [workoutList, setWorkoutList] = useState({});
  const [selectedWorkout, setSelectedWorkout] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [workoutNotes, setWorkoutNotes] = useState("");
  const [clientList, setClientList] = useState([]);
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    try {
      let select = document.getElementById("selectedWorkout");
      select.value = selectedWorkout;
      let select2 = document.getElementById("selectedClient");
      select2.value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const fetchData = async () => {
    const uman = new userHelper();
    const dman = new dataHelper();
    const response3 = await dman.getWorkoutList();
    const result3 = await response3.json();
    const response4 = await uman.getClientList();
    const result4 = await response4.json();
    const response5 = await uman.getFullList();
    const result5 = await response5.json();
    setWorkoutList(result3.data);
    setClientList(result4.data);
    setFullList(result5.data);
    setLoaded(true);
  };

  const populateWorkoutSelect = () => {
    let payload = [];
    workoutList.forEach((workout) => {
      payload.push(genTag(workout._id, workout.name));
    });
    return payload;
  };

  const genTag = (id, name) => {
    return <option value={id}>{name}</option>;
  };
  const genTag2 = (id, fname, lname) => {
    return (
      <option value={id}>
        {fname} {lname}
      </option>
    );
  };

  const handleWorkoutSelect = async (exercise) => {
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.getTargetNotes(selectedClient, exercise);
    const result = await response.json();
    setSelectedWorkout(exercise);
    setWorkoutNotes(result.data);
    setLoaded(true);
  };

  const handleClientSelect = (target) => {
    setSelectedClient(target);
  };

  const populateClientSelect = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag2(client._id, client.fname, client.lname));
    });
    return payload;
  };

  if (loaded) {
    return (
      <div className="portalClientNotes">
        <h2>Client Notes</h2>
        <p>
          Here you may see the notes your clients have taken for their workouts.
        </p>
        <p>
          Select a client below and then select a workout to view that clients
          notes for that specific exercise.
        </p>
        <label for="selectedClient">Selected Client</label>
        <select
          id="selectedClient"
          name="selectedClient"
          onChange={(e) => {
            handleClientSelect(e.target.value);
          }}
        >
          <option value="">SELECT A CLIENT</option>
          {populateClientSelect()}
        </select>
        <label for="selectedWorkout">Selected Workout</label>
        <select
          id="selectedWorkout"
          name="selectedWorkout"
          onChange={async (e) => {
            await handleWorkoutSelect(e.target.value);
          }}
        >
          <option value="">SELECT A WORKOUT</option>
          {populateWorkoutSelect()}
        </select>
        {selectedWorkout ? (
          <textarea readOnly="true">{workoutNotes}</textarea>
        ) : null}
      </div>
    );
  }
};

export default PortalClientNotes;
