import { Outlet } from "react-router";
import ClientHeader from "./comps/ClientHeader";
import ClientFooter from "./comps/ClientFooter";

export default function App(props) {

  return (
    <div className="app">
      <ClientHeader />
      <Outlet />
      <ClientFooter />
    </div>
  );
}
