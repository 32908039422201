export default class loginHelper {
  async tryLogin(email, pass) {
    try {
      let response = await fetch("/api/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email: email,
          password: pass,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async triggerPasswordReset(email) {
    try {
      let response = await fetch("/api/client/password/triggerreset", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email: email,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
