import { useEffect, useState } from "react";
import Loading from "../Loading";
import dataHelper from "../../helpers/dataHelper";
import _ from "lodash";
import Timer from "./Timer";

const InteractiveWorkout = ({ selectedDay, activeWorkouts, workoutList }) => {
  const [loaded, setLoaded] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [exerciseNotes, setExerciseNotes] = useState(null);
  const [addNoteLoader, setAddNoteLoader] = useState(false);
  const [notesLoader, setNotesLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mg, setMg] = useState(selectedDay.musclegroup);
  const [ex, setEx] = useState(selectedDay.workouts[0]);
  const [sets, setSets] = useState(selectedDay.sets[0]);
  const [reps, setReps] = useState(selectedDay.reps[0]);

  useEffect(() => {
    handleCurrentIndexChange();
    console.log("hitting");
  }, []);

  useEffect(() => {
    handleCurrentIndexChange();
  }, [currentIndex]);

  const getExerciseNotes = async (ex) => {
    const dman = new dataHelper();
    const response = await dman.getCurrentNotes(ex);
    const result = await response.json();
    setExerciseNotes(result.data);
    setLoaded(true);
  };

  const handleCurrentIndexChange = async () => {
    setMg(selectedDay.musclegroup);
    setEx(selectedDay.workouts[currentIndex]);
    setSets(selectedDay.sets[currentIndex]);
    setReps(selectedDay.reps[currentIndex]);
    setNotesLoader(true);
    await getExerciseNotes(selectedDay.workouts[currentIndex]);
    setNotesLoader(false);
  };

  const parseTl = () => {
    let workout = workoutList.filter((workout) => workout._id == ex);
    if (workout[0]) {
      return workout[0].videolink;
    } else return "";
  };

  const tl = parseTl();

  const inc = async () => {
    const notes = document.getElementById("notes").value;
    await saveNotes(notes);
    setExerciseNotes("");
    if (currentIndex + 1 == activeWorkouts.length) {
      setCurrentIndex(0);
    } else {
      let n = currentIndex;
      n++;
      setCurrentIndex(n);
    }
  };

  const dec = async () => {
    const notes = document.getElementById("notes").value;
    await saveNotes(notes);
    setExerciseNotes("");
    if (currentIndex == 0) {
      let n = activeWorkouts.length;
      setCurrentIndex(n - 1);
    } else {
      let n = currentIndex;
      n--;
      setCurrentIndex(n);
    }
  };

  const autoSave = _.debounce(async (notes) => {
    await saveNotes(notes);
  }, 750);

  const saveNotes = async (notes) => {
    const dman = new dataHelper();
    const response = await dman.saveWorkoutNotes(ex, notes);
    const result = await response.json();
    if (loaded != true) {
      setLoaded(true);
    }
  };

  const solveWorkoutText = (wd) => {
    //  get workout associated with id
    const target = wd;
    let match;
    workoutList.forEach((workout) => {
      const { _id } = workout;
      if (target == _id) {
        match = workout;
      }
    });
    if (match) {
      return match.name;
    } else {
      return wd;
    }
  };

  const toggleTutorial = () => {
    let value = showTutorial;
    setShowTutorial(!value);
  };

  const addNote = async () => {
    setAddNoteLoader(true);
    const date = new Date();
    const time = date.toDateString();
    const set = document.getElementById("set").value;
    const rep = document.getElementById("rep").value;
    const weight = document.getElementById("weight").value;
    const note =
      "Set #: " + set + " " + "Reps: " + rep + " " + "Weight: " + weight;
    const notebox = document.getElementById("notes");
    const text = notebox.value;
    const lines = text.split("\n");
    let dateStamp = null;
    lines.forEach((line) => {
      if (line == time) {
        dateStamp = lines.indexOf(line);
      }
    });
    if (dateStamp === 0 || !!dateStamp) {
      lines.splice(dateStamp + 1, 0, note);
    } else {
      lines.unshift(time, note, "\n");
    }
    const newText = lines.join("\n");
    notebox.value = newText;
    autoSave(notebox.value);
    setAddNoteLoader(false);
  };

  if (loaded) {
    return (
      <>
        <h3>Muscle Group: {selectedDay.musclegroup}</h3>
        <div className="interactiveWorkout">
          <div className="top">
            <h3> {solveWorkoutText(ex)}</h3>
            <h3>
              {currentIndex + 1} / {activeWorkouts.length}
            </h3>
          </div>
          <div className="rowWrapper">
            <strong>Sets: </strong>
            <p>{selectedDay.sets[currentIndex]} </p>
          </div>
          <div className="rowWrapper">
            <strong>Reps: </strong>
            <p>{selectedDay.reps[currentIndex]} </p>
          </div>
          <h3>Quick Notes</h3>
          <p>Which set?</p>
          <input id="set" type="string" placeholder="Which Set?" />
          <p>How many reps?</p>
          <input id="rep" type="string" placeholder="How Many Reps?" />
          <p>How much weight?</p>
          <input id="weight" type="string" placeholder="How Much Weight?" />
          <button onClick={addNote}>
            {addNoteLoader ? <Loading /> : "Add Note"}
          </button>
          {showTutorial ? (
            <div className="videoContainer">
              <iframe
                src={tl}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : null}
          <Timer />
          <h3>Note Entry:</h3>
          {notesLoader ? (
            <Loading />
          ) : (
            <textarea
              name="notes"
              id="notes"
              placeholder="Record your notes here!"
              onChange={(e) => {
                autoSave(e.target.value);
              }}
            >
              {exerciseNotes}
            </textarea>
          )}
          <div className="bottom">
            <button
              id="dec"
              onClick={async () => {
                await dec();
              }}
            >{`<`}</button>
            <button onClick={toggleTutorial}>
              {showTutorial ? "Hide Help" : "Show Help"}
            </button>
            <button
              id="inc"
              onClick={async () => {
                await inc();
              }}
            >{`>`}</button>
          </div>
        </div>
      </>
    );
  } else return <Loading />;
};

export default InteractiveWorkout;
