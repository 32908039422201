import React, { useEffect, useState } from "react";
import Loading from "../Loading";

const WorkoutRow = (props) => {
  const workoutList = props.workoutList;
  const client = props.client;
  const [activeWorkout, setActiveWorkout] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (client) {
      const target = props.wd;
      let match;
      workoutList.forEach((workout) => {
        const { _id } = workout;
        if (target == _id) {
          match = workout;
        }
      });
      setActiveWorkout(match);
    }
    setLoaded(true);
  }, []);

  const populateWorkoutSelect = () => {
    let payload = [];
    workoutList.forEach((workout) => {
      payload.push(genTag(workout._id, workout.name));
    });
    return payload;
  };

  const genTag = (id, name) => {
    return <option value={id}>{name}</option>;
  };

  const solveWorkoutText = () => {
    //  get workout associated with id
    const target = props.wd;
    let match;
    workoutList.forEach((workout) => {
      const { _id } = workout;
      if (target == _id) {
        match = workout;
      }
    });
    if (match) {
      return match.name;
    } else {
      return props.wd;
    }
  };

  const solveWorkoutTutorialLink = () => {
    if (activeWorkout) {
      return <a target="_blank" href={activeWorkout.videolink}>Workout Tutorial</a>;
    } else {
      return <></>;
    }
  };
  if (!loaded) {
    return <Loading />;
  } else if (!client) {
    return (
      <div className="workoutCard">
        <div className="label">
          <label>Workout: </label>
          <select name={props.wid} defaultValue={props.wd ? props.wd : ""}>
            <option value="">SELECT A WORKOUT</option>
            {populateWorkoutSelect()}
          </select>
          <p>{solveWorkoutText()}</p>
        </div>
        <div className="label">
          <label>Sets: </label>
          <input
            defaultValue={props.sd ? props.sd : null}
            name={props.sid}
            type="text"
            placeholder="sets"
          />
        </div>
        <div className="label">
          <label>Reps: </label>
          <input
            defaultValue={props.rd ? props.rd : null}
            name={props.rid}
            type="text"
            placeholder="sets"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="workoutCard">
        <div className="label">
          <label>Workout: </label>
          <p>{solveWorkoutText()}</p>
          {solveWorkoutTutorialLink()}
        </div>
        <div className="label">
          <label>Sets: </label>
          <p>{props.sd ? props.sd : null}</p>
        </div>
        <div className="label">
          <label>Reps: </label>
          <p>{props.rd ? props.rd : null}</p>
        </div>
      </div>
    );
  }
};

export default WorkoutRow;
