import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import userHelper from "../helpers/userHelper";
import { useNavigate } from "react-router";

const PortalClientDatabase = () => {
  const [clientList, setClientList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedClient, setSelectedClient] = useState(``);
  const [clientData, setClientData] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    (async () => {
      await refreshClientList();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectedClient`).value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const refreshClientList = async () => {
    const helper = new userHelper();
    const response = await helper.getClientList();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setClientList(data);
  };

  const createClient = async () => {
    setLoaded(false);
    const helper = new userHelper();
    const response = await helper.createClient(
      document.getElementById(`fname`).value,
      document.getElementById(`lname`).value,
      document.getElementById(`email`).value,
      document.getElementById(`phone`).value,
      document.getElementById(`address`).value
    );
    const result = await response.json();
    alert(result.msg);
    window.location.reload(true);
  };

  const updateClient = async () => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.updateUser(
      selectedClient,
      document.querySelector(`#newfname`).value,
      document.querySelector(`#newlname`).value,
      document.querySelector(`#newemail`).value,
      document.querySelector(`#newphone`).value,
      document.querySelector(`#newaddress`).value
    );
    const result = await response.json();
    alert(result.msg);
    await refreshClientList();
    const response1 = await uman.getUserData(selectedClient);
    const result1 = await response1.json();
    const { data } = result1;
    setClientData(data);
    setLoaded(true);
  };

  const removeClient = async () => {
    const input = confirm(
      `Are you sure you want to delete this Client? This action cannot be undone and will remove all workout plans, nutrition plans, and checkins owned by this client.`
    );
    if (input === true) {
      setLoaded(false);
      const uman = new userHelper();
      const response = await uman.purgeUser(selectedClient);
      const result = await response.json();
      await refreshClientList();
      const response1 = await uman.getUserData(selectedClient);
      const result1 = await response1.json();
      const { data } = result1;
      setClientData(data);
      alert(result.msg);
      setSelectedClient("");
      setLoaded(true);
    } else {
      return;
    }
  };

  const populateSelectBox = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag(client.fname, client.lname, client._id));
    });
    return payload;
  };

  const fetchClient = async () => {
    const client = document.querySelector(`#selectedClient`).value;
    setLoaded(false);
    setSelectedClient(client);
    const uman = new userHelper();
    const response = await uman.getUserData(client);
    const result = await response.json();
    const { data } = result;
    setClientData(data);
    setLoaded(true);
  };

  const resetPassword = async () => {
    setLoaded(false);
    try {
      const uman = new userHelper();
      const response = await uman.resetPasswordAdmin(selectedClient);
      const result = await response.json();
      const { msg } = result;
      alert(msg);
    } catch (err) {
      alert(`There was an error: ${err}`);
    } finally {
      setLoaded(true);
    }
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  if (loaded) {
    return (
      <div className="portalClientDatabase">
        <h2>Client Database</h2>
        <h3>Create Client</h3>
        <input id="fname" type="fname" placeholder="enter client first name" />
        <input id="lname" type="lname" placeholder="enter client last name" />
        <input id="email" type="email" placeholder="enter client email" />
        <input id="phone" type="phone" placeholder="enter client phone" />
        <input id="address" type="address" placeholder="enter client address" />
        <button onClick={createClient}>Create Client</button>
        <h3>Edit Client</h3>
        <select id="selectedClient" onChange={fetchClient}>
          <option value="">SELECT A CLIENT TO EDIT</option>
          {populateSelectBox()}
        </select>
        {selectedClient ? (
          <>
            <input
              id="newfname"
              type="fname"
              placeholder="enter client first name"
              defaultValue={clientData.fname}
            />
            <input
              id="newlname"
              type="lname"
              placeholder="enter client last name"
              defaultValue={clientData.lname}
            />
            <input
              id="newemail"
              type="email"
              placeholder="enter client email"
              defaultValue={clientData.email}
            />
            <input
              id="newphone"
              type="phone"
              placeholder="enter client phone"
              defaultValue={clientData.phone}
            />
            <input
              id="newaddress"
              type="address"
              placeholder="enter client address"
              defaultValue={clientData.address}
            />
            <button onClick={updateClient}>Update Client</button>
            <button onClick={removeClient}>Remove Client</button>
            <button onClick={resetPassword}>Reset Password to Default</button>
          </>
        ) : null}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalClientDatabase;
