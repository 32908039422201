export default class userHelper {
  async getClientData() {
    try {
      let response = await fetch("/api/client/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getUserData(target) {
    try {
      let response = await fetch("/api/client/getspecific", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getClientList() {
    try {
      let response = await fetch("/api/client/getlist", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getFullList() {
    try {
      let response = await fetch("/api/client/getfulllist", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getElevatedList() {
    try {
      let response = await fetch("/api/employee/getelevatedlist", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async purgeUser(target) {
    try {
      let response = await fetch("/api/client/purge", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async createClient(fname, lname, email, phone, address) {
    try {
      let response = await fetch("/api/client/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
          address: address,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async createEmployee(fname, lname, email, phone, address) {
    try {
      let response = await fetch("/api/employee/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
          address: address,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateUser(target, fname, lname, email, phone, address) {
    try {
      let response = await fetch("/api/client/update", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
          address: address,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async clientUpdateEmail(email) {
    try {
      let response = await fetch("/api/client/update/email", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async clientUpdatePassword(newPass, oldPass) {
    try {
      let response = await fetch("/api/client/password/update", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPass: oldPass,
          newPass: newPass,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async resetPasswordAdmin(target) {
    try {
      let response = await fetch("/api/client/password/adminreset", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateCalendarLink(target, calendarLink) {
    try {
      let response = await fetch("/api/employee/calendarlink/update", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
          calendarLink: calendarLink,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async updateCalendarLinkOnlineOnly(target, calendarLinkOnlineOnly) {
    try {
      let response = await fetch("/api/employee/calendarlink/updateonline", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
          calendarLinkOnlineOnly: calendarLinkOnlineOnly,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getAssignedCalendarLink() {
    try {
      let response = await fetch("/api/employee/calendarlink/getassigned", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getOnlineCalendarLink(_id) {
    try {
      let response = await fetch("/api/employee/calendarlink/getonline", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body:JSON.stringify({
          _id: _id
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getEmployeeList() {
    try {
      let response = await fetch("/api/employee/getlist", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getAssignedClients(target) {
    try {
      let response = await fetch("/api/employee/assignedclients/get", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async assignClient(clientID, employeeID) {
    try {
      let response = await fetch("/api/employee/assignedclients/assign", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientID: clientID,
          employeeID: employeeID,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async unassignClient(target) {
    try {
      let response = await fetch("/api/employee/assignedclients/unassign", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target: target,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async getUnassignedClients() {
    try {
      let response = await fetch(
        "/api/employee/assignedclients/getunassigned",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
}
