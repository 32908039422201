import React, { useEffect, useState } from "react";
import userHelper from "../helpers/userHelper";
import Loading from "./Loading";
import { useNavigate } from "react-router";
import HomeIcon from "./parts/HomeIcon";
import { Link } from "react-router-dom";

const PortalHomeModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [accessLevel, setAccessLevel] = useState(0);
  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const uman = new userHelper();
    const response = await uman.getClientData();
    const result = await response.json();
    if (!result.data) {
      nav(`/login`);
    }
    const { data } = result;
    const { level } = data;
    setCurrentUser(data);
    setAccessLevel(level);
    setLoaded(true);
  };

  if (loaded && accessLevel == "2") {
    return (
      <div className="portalHomeModule">
        <h2>Dashboard Home</h2>
        <h3>
          Hello, <span className="redText">{currentUser.fname}!</span>
        </h3>
        <p>Welcome to the Admin Dashboard</p>
        <h3>Admin Tools</h3>
        <div className="cards">
          <HomeIcon link="schedulingmanager" title="Scheduling Manager" imageLink="/assets/photos/17.png" />
          <HomeIcon link="employeedatabase" title="Employee Database" imageLink="/assets/photos/25.png" />
          <HomeIcon link="employeetasking" title="Employee Tasking" imageLink="/assets/photos/14.png" />
          <HomeIcon link="clientdatabase" title="Client Database" imageLink="/assets/photos/26.png" />
        </div>
        <h3>Employee Tools</h3>
        <div className="cards">
          <HomeIcon link="newcheckins" title="New Checkins" imageLink="/assets/photos/15.png" />
          <HomeIcon link="oldcheckins" title="Old Checkins" imageLink="/assets/photos/16.png" />
          <HomeIcon link="workoutplanning" title="Workout Planning" imageLink="/assets/photos/19.png" />
          <HomeIcon link="nutritionplanning" title="Nutrition Planning" imageLink="/assets/photos/20.png" />
          <HomeIcon link="workoutmanager" title="Workout Manager" imageLink="/assets/photos/21.png" />
        </div>
        <h3>Client Tools</h3>
        <div className="cards">
          <HomeIcon link="account" title="Account" imageLink="/assets/photos/26.png" />
          <HomeIcon link="checkins" title="Checkins" imageLink="/assets/photos/15.png" />
          <HomeIcon link="workoutplan" title="Workout Plan" imageLink="/assets/photos/21.png" />
          <HomeIcon link="nutritionplan" title="Nutrition Plan" imageLink="/assets/photos/22.png" />
          <HomeIcon link="tutorials" title="Workout Tutorials" imageLink="/assets/photos/23.png" />
        </div>
      </div>
    );
  } else if (loaded && accessLevel == "1") {
    return (
      <div className="portalHomeModule">
        <h2>Dashboard Home</h2>
        <h3>
          Hello, <span className="redText">{currentUser.fname}!</span>
        </h3>
        <p>Welcome to the Employee Dashboard</p>
        <h3>Employee Tools</h3>
        <div className="cards">
          <HomeIcon link="newcheckins" title="New Checkins" imageLink="/assets/photos/15.png" />
          <HomeIcon link="oldcheckins" title="Old Checkins" imageLink="/assets/photos/16.png" />
          <HomeIcon link="workoutplanning" title="Workout Planning" imageLink="/assets/photos/19.png" />
          <HomeIcon link="nutritionplanning" title="Nutrition Planning" imageLink="/assets/photos/20.png" />
          <HomeIcon link="workoutmanager" title="Workout Manager" imageLink="/assets/photos/21.png" />
        </div>
        <h3>Client Tools</h3>
        <div className="cards">
          <HomeIcon link="account" title="Account" imageLink="/assets/photos/26.png" />
          <HomeIcon link="checkins" title="Checkins" imageLink="/assets/photos/15.png" />
          <HomeIcon link="workoutplan" title="Workout Plan" imageLink="/assets/photos/21.png" />
          <HomeIcon link="nutritionplan" title="Nutrition Plan" imageLink="/assets/photos/22.png" />
          <HomeIcon link="tutorials" title="Workout Tutorials" imageLink="/assets/photos/23.png" />
        </div>
      </div>
    );
  } else if ((loaded && accessLevel == "0")) {
    return (
      <div className="portalHomeModule">
        <h2>Dashboard Home</h2>
        <h3>
          Hello, <span className="redText">{currentUser.fname}!</span>
        </h3>
        <p>Welcome to the Client Dashboard</p>
        <h3>Client Tools</h3>
        <div className="cards">
          <HomeIcon link="account" title="Account" imageLink="/assets/photos/26.png" />
          <HomeIcon link="checkins" title="Checkins" imageLink="/assets/photos/15.png" />
          <HomeIcon link="workoutplan" title="Workout Plan" imageLink="/assets/photos/21.png" />
          <HomeIcon link="nutritionplan" title="Nutrition Plan" imageLink="/assets/photos/22.png" />
          <HomeIcon link="tutorials" title="Workout Tutorials" imageLink="/assets/photos/23.png" />
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalHomeModule;
