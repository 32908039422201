import React, { createContext, useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";

const PortalWorkoutTutorialsModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [workoutList, setWorkoutList] = useState(null);
  const [selectedWorkout, setSelectedWorkout] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const dman = new dataHelper();
    const response = await dman.getWorkoutList();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setWorkoutList(data);
    setLoaded(true);
  };

  const handleWorkoutChange = () => {
    const target = document.querySelector(`#selectedWorkout`).value;
    setLoaded(false);
    let workout = workoutList.filter((workout) => workout._id == target);
    setSelectedWorkout(workout[0]);
    setLoaded(true);
  };

  const populateWorkoutSelect = () => {
    let payload = [];
    workoutList.forEach((workout) => {
      payload.push(<option value={workout._id}>{workout.name}</option>);
    });
    return payload;
  };

  if (loaded && workoutList) {
    return (
      <div className="portalWorkoutTutorialsModule">
        <h2>Workout Tutorials</h2>
        <p>
          See all of our available workouts! Click on the drop down and start
          typing to search.
        </p>
        <select id="selectedWorkout" onChange={handleWorkoutChange}>
          <option defaultValue="">SELECT A WORKOUT</option>
          {populateWorkoutSelect()}
        </select>
        {selectedWorkout ? (
          <>
            <h3>Workout: {selectedWorkout.name}</h3>
            <div className="preWrapper">
              <p>{selectedWorkout.notes}</p>
            </div>
            <h3>How To Video</h3>
            <div className="videoContainer">
              <iframe
                src={selectedWorkout.videolink}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </>
        ) : null}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalWorkoutTutorialsModule;
