import React, { useContext } from "react";
import { NutritionClientContext } from "../PortalNutritionPlanModule";

const Meal = (props) => {
  const clientStatus = props.clientContext
  if (clientStatus == true) {
    return (
      <div className="mealCard">
        <h3>Meal: {props.mealNumber}</h3>
        <div className="label">
          <label>Protein: </label>
          <p>{props.pd}</p>
        </div>
        <div className="label">
          <label>Carbs: </label>
          <p>{props.cd}</p>
        </div>
        <div className="label">
          <label>Fats: </label>
          <p>{props.fd}</p>
        </div>
      </div>
    );
  } else if (!clientStatus) {
    return (
      <div className="mealCard">
        <h3>Meal: {props.mealNumber}</h3>
        <div className="label">
          <label>Protein: </label>
          <input
            defaultValue={props.pd}
            name={props.pid}
            type="text"
            placeholder="protein"
          />
        </div>
        <div className="label">
          <label>Carbs: </label>
          <input
            defaultValue={props.cd}
            name={props.cid}
            type="text"
            placeholder="carbs"
          />
        </div>
        <div className="label">
          <label>Fats: </label>
          <input
            defaultValue={props.fd}
            name={props.fid}
            type="text"
            placeholder="fats"
          />
        </div>
      </div>
    );
  }
};

export default Meal;
