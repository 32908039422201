import React, { useEffect, useState } from "react";
import userHelper from "../helpers/userHelper";
import dataHelper from "../helpers/dataHelper";
import Loading from "./Loading";
import { useNavigate } from "react-router";

const PortalEmployeeTasking = () => {
  const [loaded, setLoaded] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [assignedClients, setAssignedClients] = useState([]);
  const [unassignedClients, setUnassignedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const nav = useNavigate()

  useEffect(() => {
    (async () => {
      const helper = new userHelper();
      const response1 = await helper.getElevatedList();
      const result1 = await response1.json();
      const response2 = await helper.getClientList();
      const result2 = await response2.json();
      if (result1.status != `ok` || result2.status != `ok`) {
        nav(`/login`);
      }
      setEmployeeList(result1.data);
      setClientList(result2.data);
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#employee`).value = selectedEmployee;
      document.querySelector(`#client`).value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const populateEmployeeSelect = () => {
    let payload = [];
    employeeList.forEach((employee) => {
      payload.push(genTag(employee.fname, employee.lname, employee._id));
    });
    return payload;
  };

  const populateClientSelect = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag(client.fname, client.lname, client._id));
    });
    return payload;
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const setEmployee = async () => {
    const employee = document.querySelector(`#employee`).value;
    setLoaded(false);
    setSelectedEmployee(employee);
    const uman = new userHelper();
    const response = await uman.getAssignedClients(employee);
    const result = await response.json();
    const response2 = await uman.getUnassignedClients();
    const result2 = await response2.json();
    setAssignedClients(result.data);
    setUnassignedClients(result2.data);
    setLoaded(true);
  };

  const setClient = () => {
    const client = document.querySelector(`#client`).value;
    setSelectedClient(client);
  };

  const assignClient = async () => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.assignClient(selectedClient, selectedEmployee);
    const result = await response.json();
    const response2 = await uman.getAssignedClients(selectedEmployee);
    const result2 = await response2.json();
    const response3 = await uman.getUnassignedClients();
    const result3 = await response3.json();
    setUnassignedClients(result3.data);
    setAssignedClients(result2.data);
    alert(result.msg);
    setLoaded(true);
  };

  const unassignClient = async (target) => {
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.unassignClient(target);
    const result = await response.json();
    const response2 = await uman.getAssignedClients(selectedEmployee);
    const result2 = await response2.json();
    const response3 = await uman.getUnassignedClients();
    const result3 = await response3.json();
    setUnassignedClients(result3.data);
    setAssignedClients(result2.data);
    alert(result.msg);
    setLoaded(true);
  };

  const populateAssignedClients = () => {
    const payload = [];
    assignedClients.forEach((client) => {
      payload.push(
        <tr>
          <td>
            {client.fname} {client.lname}
          </td>
          <td>
            <button
              onClick={() => {
                unassignClient(client._id);
              }}
            >
              REMOVE
            </button>
          </td>
        </tr>
      );
    });
    return payload;
  };

  const populateUnassignedClients = () => {
    const payload = [];
    unassignedClients.forEach((client) => {
      payload.push(
        <tr>
          <td>
            {client.fname} {client.lname}
          </td>
          <td>
            <select id={`setEmployee${client._id}`}>
              <option value="">SELECT EMPLOYEE</option>
              {populateEmployeeSelect()}
            </select>
          </td>
          <td>
            <button
              onClick={() => {
                assignClientByID(client._id);
              }}
            >
              Assign Client
            </button>
          </td>
        </tr>
      );
    });
    return payload;
  };

  const assignClientByID = async (target) => {
    const employee = document.querySelector(`#setEmployee${target}`).value;
    setLoaded(false);
    const uman = new userHelper();
    const response = await uman.assignClient(target, employee);
    const result = await response.json();
    const response2 = await uman.getAssignedClients(selectedEmployee);
    const result2 = await response2.json();
    const response3 = await uman.getUnassignedClients();
    const result3 = await response3.json();
    setUnassignedClients(result3.data);
    setAssignedClients(result2.data);
    alert(result.msg);
    setLoaded(true);
  };

  if (loaded) {
    return (
      <div className="portalEmployeeTasking">
        <h2>Employee Tasking</h2>
        <p>Assign clients and view workload for your employees here.</p>
        <h3>Employee</h3>
        <select id="employee" onChange={setEmployee}>
          <option value="">SELECT EMPLOYEE</option>
          {populateEmployeeSelect()}
        </select>
        <h3>Client</h3>
        <select id="client" onChange={setClient}>
          <option value="">SELECT A CLIENT</option>
          {populateClientSelect()}
        </select>
        <button onClick={assignClient}>Assign Client to Employee</button>
        {selectedEmployee ? (
          <>
            <h3>Assigned Clients</h3>
            <table>{populateAssignedClients()}</table>
            <h3>Unassigned Clients</h3>
            <table>{populateUnassignedClients()}</table>
          </>
        ) : null}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default PortalEmployeeTasking;
