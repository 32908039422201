import React, { useEffect, useState } from "react";

const Timer = () => {
  const [time, setTime] = useState(60 * 2); // Change initial time to 2 minutes
  const [timerOn, setTimerOn] = useState(false);
  const [lastInterval, setLastInterval] = useState(120);

  const timeOptions = Array.from({ length: 61 }, (_, i) => i * 15);

  useEffect(() => {
    let interval = null;

    if (timerOn && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (!timerOn) {
      clearInterval(interval);
    } else if (time === 0) {
      clearInterval(interval);
      alert("Time is up!");
    }

    return () => clearInterval(interval);
  }, [timerOn, time]);

  return (
    <div className="timer">
      <h3>Timer</h3>
      <div className="time">
        <span>{("0" + Math.floor((time / 3600) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 60) % 60)).slice(-2)}:</span>
        <span>{("0" + (time % 60)).slice(-2)}</span>
      </div>
      <div className="buttons">
        {!timerOn && time > 0 && (
          <button onClick={() => setTimerOn(true)}>Start</button>
        )}
        {timerOn && <button onClick={() => setTimerOn(false)}>Stop</button>}
        {!timerOn && time >= 0 && (
          <button onClick={() => setTime(lastInterval)}>Reset</button>
        )}
      </div>
      <div className="inputs">
        <label>Set Timer:</label>
        <select
          onChange={(e) => {
            setTime(e.target.value);
            setLastInterval(e.target.value);
          }}
        >
          {timeOptions.map((time, index) => (
            <option key={index} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Timer;
