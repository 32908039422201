import React, { useEffect, useState } from "react";
import Loading from "./Loading";

const PortalEComModule = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return <Loading />;
  } else if (loaded) {
    return (
      <div className="portalEComModule">
        <h2>E-Commerce</h2>
        <p>Manage your business's online products here.</p>
        <h2>COMING SOON</h2>
      </div>
    );
  } else {
  }
};

export default PortalEComModule;
