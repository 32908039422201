import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import userHelper from "../helpers/userHelper";
import dataHelper from "../helpers/dataHelper";
import { useNavigate } from "react-router";

const PortalOldCheckinsModule = () => {
  const [loaded, setLoaded] = useState(false);
  const [clientList, setClientList] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [checkinData, setCheckinData] = useState(null);

  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    try {
      document.querySelector(`#selectedClient`).value = selectedClient;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const fetchData = async () => {
    const uman = new userHelper();
    const response = await uman.getFullList();
    const result = await response.json();
    if (result.status != `ok`) {
      nav(`/login`);
    }
    const { data } = result;
    setClientList(data);
    setLoaded(true);
  };

  const populateSelectBox = () => {
    let payload = [];
    clientList.forEach((client) => {
      payload.push(genTag(client.fname, client.lname, client._id));
    });
    return payload;
  };

  const genTag = (fname, lname, _id) => {
    return (
      <option value={_id}>
        {fname} {lname}
      </option>
    );
  };

  const selectClient = async () => {
    const query = document.querySelector(`#selectedClient`).value;
    setSelectedClient(query);
    setLoaded(false);
    //  get client checkins
    const dman = new dataHelper();
    const response = await dman.getOldCheckins(query);
    const result = await response.json();
    const { data } = result;
    setCheckinData(data);
    setLoaded(true);
  };

  const populateCheckins = () => {
    let payload = [];
    checkinData.forEach((checkin) => {
      const dateObj = new Date(checkin.date * 1);
      const dateFormatted = dateObj.toLocaleString();
      payload.push(
        tag(
          checkin.fname,
          checkin.lname,
          checkin.email,
          checkin.weight,
          checkin.bodyfat,
          checkin.adherence,
          checkin.struggles,
          checkin.digestion,
          checkin.hunger,
          checkin.sleep,
          checkin.stressors,
          checkin.feelings,
          dateFormatted
        )
      );
    });
    return payload;
  };

  const tag = (
    fname,
    lname,
    email,
    weight,
    bodyfat,
    adherence,
    struggles,
    digestion,
    hunger,
    sleep,
    stressors,
    feelings,
    date
  ) => {
    return (
      <div className="checkinForm">
        <h3>Checkin Form</h3>
        <p>
          <strong>{date}</strong>
        </p>
        <p>
          <strong>First Name:</strong> {fname}
        </p>
        <p>
          <strong>Last Name:</strong> {lname}
        </p>
        <p>
          <strong>Email:</strong> {email}
        </p>
        <p>
          <strong>Weight:</strong> {weight}
        </p>
        <p>
          <strong>Bodyfat:</strong> {bodyfat}
        </p>
        <p>
          <strong>Adherence to the plan:</strong> {adherence}
        </p>
        <p>
          <strong>Struggles:</strong> {struggles}
        </p>
        <p>
          <strong>Digestive Health:</strong> {digestion}
        </p>
        <p>
          <strong>Hunger:</strong> {hunger}
        </p>
        <p>
          <strong>Sleep Schedule:</strong> {sleep}
        </p>
        <p>
          <strong>Other Stressors:</strong> {stressors}
        </p>
        <p>
          <strong>General Feelings:</strong> {feelings}
        </p>
      </div>
    );
  };

  if (!loaded) {
    return <Loading />;
  } else if (loaded) {
    return (
      <div className="portalOldCheckinsModule">
        <h2>Old Checkins</h2>
        <p>Manage client's old checkins here.</p>
        <h3>Select a Client</h3>
        <select
          id="selectedClient"
          onChange={() => {
            selectClient();
          }}
        >
          <option value="">SELECT A CLIENT</option>
          {populateSelectBox()}
        </select>
        <h3>Old Checkins</h3>
        {checkinData ? (
          <>
            <strong>Count: {checkinData.length}</strong>
            <div className="forms">{populateCheckins()}</div>
          </>
        ) : null}
      </div>
    );
  }
};

export default PortalOldCheckinsModule;
